import { type Sistema, sistemas } from 'constants/sistemas';

/**
 * @deprecated - Utilizar direto de /config/index.ts
 */
const useSistema = (): Sistema => {
  const _nomeSistema = window?.location?.hostname?.split('.')[1].toLowerCase();

  return sistemas[_nomeSistema];
};

export default useSistema;

import { create } from 'zustand';

import type { IntegradoComDominioDialogStore } from './integradoComDominioDialog.types';

export const useIntegradoComDominioDialogStore = create<IntegradoComDominioDialogStore>(set => ({
  open: false,
  dadosCliente: null,
  dadosIntegracao: null,

  handleOpen: dadosCliente => set({ open: true, dadosCliente }),
  handleClose: () => set({ open: false, dadosIntegracao: null, dadosCliente: null }),
  setDadosIntegracao: dadosIntegracao => set({ dadosIntegracao }),
}));

import { useMemo } from 'react';
import useAuthStore from 'store/auth/auth';

const _emailSBSistemas = '@sbsistemas.com.br';

const useUsuarioSBSistemas = () => {
  const perfil = useAuthStore((state) => state.perfil);

  const isUsuarioSBSistemas = useMemo(() => {
    const _emailUsuario = perfil?.email;

    if (!_emailUsuario) {
      return false;
    }

    return _emailUsuario.includes(_emailSBSistemas);
  }, [perfil?.email]);

  return {
    isUsuarioSBSistemas,
  };
};

export default useUsuarioSBSistemas;

import { isEmpty } from 'lodash-es';
import { type PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react';
import useAppStore from 'store/app/app';
import { useCobrancasStore } from 'store/cobrancas/cobrancasStore';
import type { ModalBoletoProviderTypes } from './modalBoletoProvider.types';
import useSistema from 'hooks/useSistema';
import { api } from 'api/api';
import { base64ToBlob } from '@s_mart/utils';
import { ModalBoletoSituacao } from 'store/app/app.types';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'store/auth/auth';
import dayjs from 'dayjs';
import { useClientesStore } from 'store/clientes/clientes';

const ModalBoletoContext = createContext({} as ModalBoletoProviderTypes);

const ModalBoletoProvider = ({ children }: PropsWithChildren) => {
  const [modalBoleto, setNotification, setLoading, setModalBoleto, handleErrors] = useAppStore((state) => [
    state.modalBoleto,
    state.setNotification,
    state.setLoading,
    state.setModalBoleto,
    state.handleErrors,
  ]);
  const [configuracoesUsuario, buscarConfiguracoesUsuario, buscarMeusDados] = useAuthStore((state) => [
    state.configuracoesUsuario,
    state.buscarConfiguracoesUsuario,
    state.buscarMeusDados,
  ]);
  const buscarClientes = useClientesStore((state) => state.buscarClientes);
  const navigate = useNavigate();
  const { boletosEmAberto, boletosVencidos } = useCobrancasStore();

  const { whatsApp } = useSistema();

  const boleto = useMemo(
    () => (modalBoleto === ModalBoletoSituacao.BOLETO_DISPONIVEL ? boletosEmAberto[0] : boletosVencidos[0]),
    [modalBoleto, boletosEmAberto, boletosVencidos],
  );

  const handleCopiarLinhaDigitavel = useCallback(() => {
    navigator.clipboard.writeText(boleto.linhaDigitavel);
    setNotification({
      title: 'Copiado com sucesso!',
    });
  }, [boleto]);

  const handleAbrirBoleto = useCallback(async () => {
    try {
      setLoading(true);

      const { data: response } = await api.empresa.baixarBoleto(boleto.identificador);

      const _blob = base64ToBlob(response.data.boleto, 'application/pdf');
      const _objectURL = URL.createObjectURL(_blob);

      window.open(_objectURL, '_blank');
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [boleto, modalBoleto]);

  const handleFalarComSuporte = () => {
    const _url = `https://api.whatsapp.com/send?phone=${whatsApp}`;

    window.open(_url);
  };

  const handleRedirecionarMeuPlano = () => {
    navigate('/config/minha-conta?tab=meuPlano');
    setModalBoleto(null);
  };

  const handleMarcarComoPago = useCallback(async () => {
    try {
      setLoading(true);
      await api.empresa.solicitarDesbloqueio();
      setModalBoleto(ModalBoletoSituacao.CONFIRMACAO_DESBLOQUEIO);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [boleto, configuracoesUsuario]);

  const handleClose = useCallback(async () => {
    if (modalBoleto === ModalBoletoSituacao.BOLETO_DISPONIVEL) {
      await api.usuarios.atualizarConfiguracoes({
        configuracao: {
          ...configuracoesUsuario,
          boletosVisualizados: {
            ...configuracoesUsuario?.boletosVisualizados,
            [boleto.identificador]: dayjs().toJSON(),
          },
        },
      });
      buscarConfiguracoesUsuario();
    }
    if (modalBoleto === ModalBoletoSituacao.CONFIRMACAO_DESBLOQUEIO) {
      await api.usuarios.atualizarConfiguracoes({
        configuracao: {
          ...configuracoesUsuario,
          boletosMarcadosComoPago: {
            ...configuracoesUsuario?.boletosMarcadosComoPago,
            [boleto.identificador]: dayjs().toJSON(),
          },
        },
      });
      buscarMeusDados();
      buscarConfiguracoesUsuario();
      buscarClientes();
    }
    setModalBoleto(null);
  }, [modalBoleto, boleto, configuracoesUsuario]);

  return (
    <ModalBoletoContext.Provider
      value={{
        handleCopiarLinhaDigitavel,
        handleFalarComSuporte,
        handleAbrirBoleto,
        handleRedirecionarMeuPlano,
        handleMarcarComoPago,
        handleClose,
        boleto,
      }}
    >
      {children}
    </ModalBoletoContext.Provider>
  );
};

export const useModalBoleto = () => {
  const context = useContext(ModalBoletoContext);

  if (isEmpty(context)) {
    throw new Error('useModalBoleto must be used inside a ModalBoletoProvider');
  }

  return context;
};

export default ModalBoletoProvider;

import { Box, Popper, Backdrop, Stack } from '@mui/material';
import { StyledNovidadesPopper } from './novidadesPopper.styles';
import { EmptyState, IconButton, LIcon, Typography } from '@s_mart/core';
import { lineTimes } from '@s_mart/regular-icons';
import { NovidadesCard } from '../novidadesCard/novidadesCard';
import type { NovidadePopperProps } from '../novidades.types';

export const NovidadesPopper = ({ anchorEl, onClose, novidadesItems }: NovidadePopperProps) => {
  return (
    <Backdrop open sx={{ zIndex: 103 }} onClick={onClose}>
      <Popper
        sx={{ zIndex: 104 }}
        open
        anchorEl={anchorEl}
        placement='bottom'
        disablePortal={false}
        onClick={(e) => e.stopPropagation()}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: false,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        <StyledNovidadesPopper>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography variant='bodyLG'>Últimas novidades</Typography>
            <IconButton onClick={onClose} variant='text' color='neutral'>
              <LIcon icon={lineTimes} size='32px' removeMargin />
            </IconButton>
          </Box>
          <Stack gap={2} overflow='auto'>
            {novidadesItems.length ? (
              novidadesItems.map((n) => <NovidadesCard key={`${n.titulo}-${n.codigo}`} {...n} />)
            ) : (
              <EmptyState title='Nenhuma novidade' />
            )}
          </Stack>
        </StyledNovidadesPopper>
      </Popper>
    </Backdrop>
  );
};

export enum EnumAutomacaoAgronota {
  /**
   * O cliente está utilizando as regras próprias para a automação do agronota
   */
  RegrasProprias = 0,
  /**
   * O cliente está utilizando as regras padrão do saas do agronota para a automação
   */
  CentralFinanceira = 1,
}

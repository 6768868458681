import { colorPalette } from '@s_mart/tokens';
import styled from '@emotion/styled';
import { HelpMenuState } from './appBar';

export const AppBarButton = styled.div<{ menuOpen?: HelpMenuState }>`
  display: flex;
  align-items: center;

  color: ${colorPalette.neutral[500]};
  padding: 2px 8px 2px 4px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  gap: 2px;

  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${colorPalette.neutral[30]};
  }

  > i:last-child {
    ${({ menuOpen }) => menuOpen && 'transform: rotate(180deg);'};
    transition: all 0.3s ease-in-out;
  }
`;

export const HelpButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 6px 16px;

  &:hover {
    background-color: ${colorPalette.neutral[30]};
    transition: all 0.3s ease-in-out;
  }
`;

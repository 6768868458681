import styled from '@emotion/styled';
import { colorPalette } from '@s_mart/tokens';
import { primaryColor } from 'styles/themes/theme';

export const CardButton = styled.div`
  border: 1px solid ${colorPalette.neutral[50]};
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  gap: 50px;
  height: 225px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${primaryColor};
  }

  @media (max-width: 1200px) or (max-height: 700px) {
    padding: 12px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    height: auto;
    display: grid;
    grid-template-columns: 20% 1fr;
    grid-template-rows: 72px;

    & img {
      width: 100%;
    }
  }
`;

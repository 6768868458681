import { toRem } from '@s_mart/utils';
import styled from '@emotion/styled';

export const PrivateContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

interface OutletContainerProps {
  dataContratacao?: string | null;
}

const paddingOutlet = toRem(24);
const tamanhoAppBar = toRem(49);
const tamanhoBannerContratacao = toRem(40);

export const OutletContainer = styled.div<OutletContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${paddingOutlet} ${paddingOutlet} ${paddingOutlet} ${paddingOutlet};

  overflow-y: auto;

  overflow-y: auto;

  height: ${({ dataContratacao }) => {
    if (dataContratacao === null) {
      return `calc(100% - ${tamanhoAppBar} - ${tamanhoBannerContratacao} - (${paddingOutlet} * 2))`;
    }
    return `calc(100% - ${tamanhoAppBar} - (${paddingOutlet} * 2))`;
  }};
`;

import { type PropsWithChildren, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import 'moment/locale/pt-br';
import { FinancialProvider } from '@grupo-sbsistemas/particle-financeiro';
import { ContentContainer, OutletContainer, PrivateContainer } from './private.styles';
import { Loading } from '../..';
import { AppBar } from './appBar/appBar';
import { Drawer } from './drawer/drawer';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { API_URL } from 'api/urls';
import BottomBar from 'components/bottomBar/bottomBar';
import OnboardingAgronota from 'containers/onboarding/agronota/onboardingAgronota';
import { ConfirmationServiceProvider } from 'contexts/confirmationContext';
import { useNotification } from 'hooks/useNotification/useNotification';
import { BannerContratacao } from 'shared/bannerContratacao/bannerContratacao';
import { Dialogs } from './dialogs/dialogs';
import { usePrivate } from './usePrivate';
import useSistema from 'hooks/useSistema';
import { EscrituracaoRetroativaV2Provider } from 'contexts/escrituracaoRetroativaV2/escrituracaoRetroativaV2';
import ModalBoleto from 'shared/modalBoleto/modalBoleto';
import ModalBoletoProvider from 'shared/modalBoleto/context/modalBoletoProvider';
import Cookies from 'js-cookie';
import { EnumSistemas } from 'constants/sistemas';
import { privateRouteMiddleware } from 'containers/hocs/privateRouteMiddleware/privateRouteMiddleware';
import { usePostHogTrackPages } from 'lib/posthog/usePostHogTrackPages';

const Layout = () => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down(768));
  /**
   * Hook para exibir notificações
   */

  useNotification();
  /**
   * Hooks com diversas funções de uso geral
   */
  const { mostrarOnboarding, token, meusDados, modalBoleto } = usePrivate();

  const { code } = useSistema();
  usePostHogTrackPages();

  const Providers = useCallback(
    ({ children }: PropsWithChildren) => {
      if (code === EnumSistemas.AGRONOTA) {
        return <EscrituracaoRetroativaV2Provider>{children}</EscrituracaoRetroativaV2Provider>;
      }

      return <>{children}</>;
    },
    [code],
  );

  return (
    <ConfirmationServiceProvider>
      <FinancialProvider
        apiUrl={API_URL}
        token={token || Cookies.get('id_token') || ''}
        sistemaID={9}
        sistemaNome='Parceiro'
      >
        <PrivateContainer>
          <Loading />
          {isMobile ? <BottomBar /> : <Drawer />}
          <ContentContainer>
            <AppBar />
            <BannerContratacao />
            {modalBoleto !== null && (
              <ModalBoletoProvider>
                <ModalBoleto />
              </ModalBoletoProvider>
            )}
            {mostrarOnboarding && <OnboardingAgronota />}
            <Dialogs />
            <OutletContainer dataContratacao={meusDados?.dataContratacao}>
              <Providers>
                <Outlet />
              </Providers>
            </OutletContainer>
          </ContentContainer>
        </PrivateContainer>
      </FinancialProvider>
    </ConfirmationServiceProvider>
  );
};

export default privateRouteMiddleware(Layout);

import styled from '@emotion/styled';

export const PopoverInterno = styled.div`
  min-width: 150px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 20px;
  padding: 8px 0px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const Arrow = styled.div<{ posicao: 'start' | 'center' | 'end' }>`
  position: absolute;
  margin: 0px 5px;
  right: ${({ posicao }) => (posicao === 'center' ? 'calc(50% - 15px)' : posicao === 'end' ? '0%' : undefined)};
  left: ${({ posicao }) => (posicao === 'center' ? 'calc(50% - 15px)' : posicao === 'start' ? '0%' : undefined)};
  top: -8px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
`;

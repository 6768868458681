import { SituacoesClientesAgronota } from 'containers/clientesAgronota/filtrosClientesAgronota/filtrosClientesAgronota.types';

// Tags do analytics devem ter no máximo 40 caracteres

export const ANALYTICS_ACTIONS = {
  RESUMO: {
    CERTIFICADOS_VENCIDOS: 'resumo / certificados vencidos',
    CERTIFICADOS_VENCENDO: 'resumo / certificados vencendo',
    PRODUTORES_SEM_CERTIFICADO: 'resumo / produtores sem certificado',
    NOTAS_EMITIDAS_REVISAR: 'resumo / notas emitidas revisar',
    NCMS_SEM_REGRAS: 'resumo / ncms sem regras',
    LANCAMENTOS_VENCIDOS: 'resumo / lançamentos vendidos',
    DOCUMENTOS_NAO_PROCESSADOS: 'resumo / documentos não processados',
    REGRAS_SUGESTOES_ABRIR_MODAL: 'resumo / abrir modal sugestoes',
    REGRAS_SUGESTOES_ABRIR_MODAL_PELO_AVISO: 'resumo / abrir modal sugestoes aviso',
    REGRAS_SUGESTOES_INCLUIR: 'resumo / incluir sugestões nas regras',
    REGRAS_SUGESTOES_IGNORAR: 'resumo / ignorar sugesões',

    FILTRAR_ANO_AUTOMACOES: 'resumo / filtrar ano automacoes',
  },
  CLIENTES: {
    NOVO_CLIENTE: 'clientes / novo cliente',
    FILTROS: (situacao: SituacoesClientesAgronota) => {
      switch (situacao) {
        case SituacoesClientesAgronota.TODOS:
          return 'clientes / filtro / todos';
        case SituacoesClientesAgronota.ATIVOS:
          return 'clientes / filtro / ativos';
        case SituacoesClientesAgronota.INATIVOS:
          return 'clientes / filtro / inativos';
        case SituacoesClientesAgronota.BLOQUEADOS:
          return 'clientes / filtro / bloqueados';
        case SituacoesClientesAgronota.COM_PENDENCIAS:
          return 'clientes / filtro / todos com pendências';
        case SituacoesClientesAgronota.CERTIFICADOS_VENCIDOS:
          return 'clientes / filtro / certs vencidos';
        case SituacoesClientesAgronota.CERTIFICADOS_VENCER:
          return 'clientes / filtro / certs vencendo';
        case SituacoesClientesAgronota.CLIENTES_SEM_CERTIFICADO:
          return 'clientes / filtro / sem certificado';
        case SituacoesClientesAgronota.NOTAS_REVISAR:
          return 'clientes / filtro / nfe emitidas revisar';
        case SituacoesClientesAgronota.LANCAMENTOS_VENCIDOS:
          return 'clientes / filtro / lançamentos vencidos';
        case SituacoesClientesAgronota.DOCUMENTOS_NAO_PROCESSADOS:
          return 'clientes / filtro / docs não processados';
        case SituacoesClientesAgronota.SEM_PENDENCIAS:
          return 'clientes / filtro / sem pendências';
        default:
          return 'clientes / filtro';
      }
    },
  },
  PRODUTOS: {
    NOVO_PRODUTO: 'produtos / novo produto',
    EDITAR_PRODUTO: 'produtos / editar produto',
    EXCLUIR_PRODUTO: 'produtos / excluir produto',
  },
  SIMULADOR_IR: {
    SELECIONAR_PRODUTOR: 'simulador IR / selecionar produtor',
    SELECIONAR_ANO: (ano: string) => `simulador IR / selecionar ano ${ano}`,
    NOVO_PRODUTOR: 'simulador IR / novo produtor',
    EDITAR_PRODUTOR: 'simulador IR / editar produtor',
    SIMULAR_IMPOSTO: 'simulador IR / simular imposto',
    ENVIAR_POR_EMAIL: 'simulador IR / enviar por email',
    IMPRIMIR: 'simulador IR / imprimir',
    VOLTAR_A_TELA_INICIAL: 'simulador IR / voltar a tela inicial',
  },
  CATEGORIAS: {
    NOVA_CATEGORIA: 'categorias / nova categoria',
    NOVA_SUBCATEGORIA: 'categorias / nova subcategoria',
    EDITAR: 'categorias / editar categoria',
    EXCLUIR: 'categorias / excluir categoria',
  },
  CONFIGURACOES: {
    USUARIOS: 'configurações / usuários',
    CONVIDAR_USUARIO: 'configurações / convidar usuário',
    MINHA_CONTA: 'configurações / minha conta',
    MEU_PLANO: 'configurações / meu plano',
    LANCAMENTOS: 'configurações / lançamentos',
  },
  ESCRITURACAO_AUTOMATICA: {
    ESCRITURACAO_RETROATIVA: 'automações / escrituração retroativa',
  },
  NATUREZAS_OPERACAO: {
    CRIAR: 'naturezas / nova natureza',
    EDITAR: 'naturezas / editar natureza',
    ATIVAR: 'naturezas / ativar natureza',
    INATIVAR: 'naturezas / inativar natureza',
  },
  NOVIDADES: {
    ABRIR: 'novidades / abrir',
    REDIRECT: 'novidades / redirect',
  },
} as const;

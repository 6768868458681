import { api } from 'api/api';
import { sistemas } from 'constants/sistemas';
import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';
import type { Perfil } from 'store/auth/auth.types';

export const freshChat = async (profile: Perfil) => {
  // @ts-ignore
  window.fcWidget.init({
    token: sistemas[window?.location?.hostname?.split('.')[1]].freshchat,
    host: 'https://wchat.freshchat.com',
    config: {
      headerProperty: {
        hideChatButton: true,
      },
    },
    externalId: `${profile.razao_social} - ${profile.sub}`,
    firstName: profile.nome,
    email: profile.email,
    siteId: profile.razao_social,
    empresa: profile.razao_social,
  });

  //@ts-ignore
  window.fcWidget.user.get(function (resp) {
    const status = resp.status;
    if (status !== 200) {
      //@ts-ignore
      window.fcWidget.user.setProperties({
        externalId: `${profile.razao_social} - ${profile.sub}`,
        firstName: profile.nome,
        email: profile.email,
        siteId: profile.razao_social,
        empresa: profile.razao_social,
      });

      //@ts-ignore
      window.fcWidget.on('user:created', async function (resp) {
        const status = resp.status;
        const data = resp.data;
        if (status === 200) {
          if (data.restoreId) {
            const { data: result } = await api.empresa.salvarFreshChatID(data.restoreId);
            if (result.errors || result.authErrors || result.error) {
              useAppStore.getState().handleErrors(result);
            } else {
              useAuthStore.getState().tratarDadosToken(result.data.token);
            }
          }
        }
      });
    }
  });

  //@ts-ignore
  window.fcWidget.open();
  //@ts-ignore
  window.fcWidget.show();
};

import { useMemo, useState, type MouseEvent, useCallback } from 'react';
import { intersection } from 'lodash-es';
import type { NovidadeItem } from './novidades.types';
import useAuthStore from 'store/auth/auth';
import dayjs from 'dayjs';
import useAnalytics from 'hooks/useAnalytics';
import useClarity from 'hooks/useClarity';
import { ANALYTICS_ACTIONS } from 'constants/analyticsActions';
import { CLARITY_TAGS } from 'constants/clarityTags';

const isArrayOfNumbers = (input: any): input is number[] => {
  return Array.isArray(input) && input.every((val) => typeof val === 'number');
};

export const useNovidades = () => {
  const [mostrarPopper, setMostrarPopper] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [recursosDisponiveis, meusDados] = useAuthStore((state) => [state.recursosDisponiveis, state.meusDados]);

  const { analyticsEventTracker } = useAnalytics();
  const { setTag } = useClarity();

  const novidadesVisualizadasLocalItem = localStorage.getItem('novidadesVisualizadas');

  let novidadesVisualizadas: number[] | null = null;

  if (novidadesVisualizadasLocalItem !== null) {
    novidadesVisualizadas = JSON.parse(novidadesVisualizadasLocalItem);
  }
  // Colocar novas novidades no topo
  const _novidadesItems: NovidadeItem[] = [
    {
      codigo: 15,
      titulo: 'Automações mais inteligentes!',
      descricao: (
        <>
          Com as novas melhorias nas automações, o sistema agora aguardará automaticamente as contra-notas emitidas
          contra os produtores. Isso evita a duplicidade de lançamentos e garante que os lançamentos estejam sempre
          atualizados com os valores corretos.
        </>
      ),
      data: dayjs('2024-09-09'),
      url: 'https://api.whatsapp.com/send?phone=555436984215',
      labelRedirect: 'Quero enviar minha opinião!',
      recursos: ['escrituracao-automatica'],
    },
    {
      codigo: 13,
      titulo: 'Seus clientes mais independentes!',
      descricao: (
        <>
          Agora, quando os boletos forem gerados <b>diretamente para os produtores</b>, eles poderão baixá-los
          diretamente no sistema deles com total facilidade e sem complicações!
        </>
      ),
      data: dayjs('2024-09-09'),
      url: 'https://api.whatsapp.com/send?phone=555436984215',
      labelRedirect: 'Quero enviar minha opinião!',
    },
    {
      codigo: 9,
      titulo: 'Emissão de Certificado Digital',
      descricao: (
        <>
          Estamos animados em anunciar nossa mais recente parceria para a emissão de certificados digitais!
          <br />
          <br />
          Isso significa que agora você pode obter seu certificado digital de forma mais ágil e eficiente, simplificando
          todo o processo. Estamos comprometidos em tornar a obtenção do seu certificado uma experiência descomplicada e
          rápida.
          <br />
          <br />
          Entre em contato conosco através dos nossos canais de atendimento para saber mais.
        </>
      ),
      data: dayjs('2024-05-23'),
      url: 'https://api.whatsapp.com/send?phone=555436421346',
    },
    {
      codigo: 8,
      titulo: 'Prorrogação do prazo de entrega do IRPF no estado do Rio Grande do Sul',
      descricao: (
        <>
          Olá! Você sabia que o prazo de entrega do imposto de renda foi prorrogado? Para os munícipios atingidos pelas
          enchentes no Rio Grande do Sul, o novo prazo estabelece que a entrega seja feita até 31 de agosto de 2024!
          <br />
          <br /> Na PORTARIA RFB Nº 415, DE 6 DE MAIO DE 2024 estão descritos quais são os 336 municípios do RS
          beneficiados com essa prorrogação e também com o pagamento de tributos federais, incluindo parcelamentos, e o
          cumprimento de obrigações acessórias para os contribuintes domiciliados nessas cidades.
          <br /> <br /> Fique atento e não perca o prazo!
        </>
      ),
      data: dayjs('2024-05-14'),
      labelRedirect: 'Saiba mais',
      url: 'https://www.in.gov.br/en/web/dou/-/portaria-rfb-n-415-de-6-de-maio-de-2024-557992616',
    },
    {
      codigo: 5,
      titulo: 'O período de entrega da declaração de IRPF começa hoje 15/03!',
      descricao: (
        <>
          A Receita Federal informou, às 8 horas da manhã desta sexta-feira (15), que iniciou o prazo de entrega da
          Declaração do Imposto de Renda 2024, ano-calendário 2023.
          <br />
          <br />
          Caso precise de auxílio com essa demanda, basta nos sinalizar, nossa equipe está preparada para oferecer todo
          o suporte necessário!
        </>
      ),
      data: dayjs('2024-03-15'),
      dataFinal: dayjs('2024-05-31'),
      recursos: ['livro-caixa'],
      labelRedirect: 'Preciso de ajuda',
      url: 'https://api.whatsapp.com/send?phone=555436984215&text=Ol%C3%A1,%20estou%20fazendo%20o%20IRPF%20e%20gostaria%20de%20aux%C3%ADlio.%20',
      img: {
        src: '/static/images/lembrete-irpf.webp',
        width: '60%',
      },
    },
    {
      codigo: 4,
      titulo: 'Vamos automatizar até 95% dos lançamentos?!',
      descricao: (
        <>
          Nosso time de especialistas montou um pacote com várias sugestões de regras de automação para facilitar o seu
          trabalho! Basta clicar no botão de atualização de regras e escolher quais daquelas regras atendem a sua
          operação.
          <br />
          <br />
          As sugestões de escrituração são para documentos vinculados à atividade rural (vão para o Livro Caixa) e
          também para documentos não vinculados a atividade rural (outras atividades), trazendo um acompanhamento
          completo do financeiro do Produtor Rural
        </>
      ),
      data: dayjs('2024-03-13'),
      dataFinal: dayjs('2024-04-13'),
      url: 'https://ajuda.agronota.com.br/index.php/2024/02/27/parceiro-como-cadastrar-as-automacoes/',
      labelRedirect: 'Manual de automações',
      img: {
        src: '/static/gifs/automacoes.gif',
      },
      recursos: ['escrituracao-automatica'],
    },
    {
      codigo: 3,
      titulo: 'Naturezas de operação em um lugar só!',
      descricao: (
        <>
          Simplificamos a forma de configurar o emissor de <b>NFP-e!</b>
          <br />
          <br />
          Agora é possível configurar as naturezas de operação e situação tributárias para todos os Produtores em um
          lugar só.
          <br />
          <br />
          As naturezas quando ativas passam a aparecer para toda a sua carteira de clientes, então, configurando uma
          vez, <b>todos os seus Produtores</b> poderão utilizar o emissor <b>já com as regras de tributação.</b>
        </>
      ),
      data: dayjs('2024-03-12'),
      dataFinal: dayjs('2024-06-12'),
      img: {
        src: '/static/gifs/naturezas.gif',
      },
      url: 'https://api.whatsapp.com/send?phone=555436421346&text=Ola,%20acabei%20de%20ver%20a%20novidade%20sobre%20as%20naturezas%20de%20opera%C3%A7%C3%A3o%20e%20gostaria%20de%20dar%20a%20minha%20opini%C3%A3o!',
      labelRedirect: 'Quero deixar minha opinião!',
      recursos: ['nota-fiscal'],
    },
    {
      codigo: 2,
      titulo: 'Uma nova visão de como está a eficiência do trabalho!',
      data: dayjs('2024-03-07'),
      descricao: (
        <>
          Sabendo que o Agronota pode automatizar quase todo o trabalho de análise e lançamento de notas, incluímos
          alguns indicadores para que você consiga saber a situação atual e poder pensar melhor na otimização.
          <br />
          <br />
          Basicamente, para ser mais eficiente, você precisará ir criando as regras para ignorar notas ou para lançá-las
          automaticamente.
          <br />
          <br />
          Caso precise de ajuda para criar essas regras, ou necessite de um pacote de regras de automação e plano de
          contas atualizado, é só nos chamar no atendimento.
        </>
      ),
      recursos: ['escrituracao-automatica'],
      url: 'https://wa.me/555436421346?text=Ol%C3%A1%2C+gostaria+de+conhecer+o+pacote+de+regras+de+automa%C3%A7%C3%A3o%21',
    },
    {
      codigo: 1,
      titulo: 'Fique de olho aqui!',
      descricao: (
        <>
          Olá, pensando em te deixar por dentro de tudo que estamos criando, passaremos a utilizar esse canal para te
          contar todas as novidades do Agronota. <br />
          <br />
          Temos muitas novidades chegando nos próximos meses, então fique atento nas notificações que estão por vir.
        </>
      ),
      data: dayjs('2024-03-04'),
      dataFinal: dayjs('2024-05-13'),
    },
  ];

  const verificarRecursos = useCallback(
    (recursosRequeridos: string[]) => recursosRequeridos.every((recurso) => recursosDisponiveis?.[recurso]),
    [recursosDisponiveis],
  );

  const novidadesFiltradas = useMemo(() => {
    const filtradas: NovidadeItem[] = [];

    for (const novidade of _novidadesItems) {
      if (novidade.recursos && !verificarRecursos(novidade.recursos)) {
        continue;
      }
      if (meusDados?.dataContratacao !== undefined && dayjs(meusDados.dataContratacao).isAfter(novidade.data)) {
        continue;
      }
      if (novidade.dataFinal !== undefined && dayjs(novidade.dataFinal).isBefore(dayjs())) {
        continue;
      }

      filtradas.push(novidade);
    }
    return filtradas;
  }, [_novidadesItems, recursosDisponiveis, meusDados?.dataContratacao]);

  const mostrarBadge = useMemo(() => {
    const codigosNovidades: number[] = novidadesFiltradas.map((n) => n.codigo);

    if (novidadesVisualizadas === null && codigosNovidades.length) {
      return true;
    }

    return (
      isArrayOfNumbers(novidadesVisualizadas) &&
      intersection(codigosNovidades, novidadesVisualizadas).length !== codigosNovidades.length
    );
  }, [novidadesVisualizadas, _novidadesItems, novidadesFiltradas]);

  const handleAbrirNovidades = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setMostrarPopper((curr) => (curr !== null ? null : e.currentTarget));
      analyticsEventTracker({
        action: ANALYTICS_ACTIONS.NOVIDADES.ABRIR,
      });
      setTag(CLARITY_TAGS.NOVIDADES.ABRIR);
      localStorage.setItem('novidadesVisualizadas', JSON.stringify(novidadesFiltradas.map((n) => n.codigo)));
    },
    [novidadesFiltradas],
  );

  return { novidadesFiltradas, mostrarBadge, mostrarPopper, setMostrarPopper, handleAbrirNovidades };
};

import { axiosInstance, type DataResponse } from 'api/axiosConfig';
import type {
  BuscarCobrancasResponse,
  BuscarMeusDadosResponse,
  EditarMeusDadosBody,
  Empresa,
  RecursosResponse,
} from './empresa.types';

export const empresa = {
  buscarDados: () => axiosInstance.get<DataResponse<BuscarMeusDadosResponse>>('/parceiro/meus-dados'),

  buscarRecursos: () => axiosInstance.get<DataResponse<RecursosResponse>>(`/parceiro/meus-dados/meu-plano/recursos`),

  buscarCobrancas: () => axiosInstance.get<DataResponse<BuscarCobrancasResponse[]>>(`/parceiro/meus-dados/cobrancas`),

  baixarBoleto: (boletoIdentificador: string) =>
    axiosInstance.get<DataResponse<{ boleto: string }>>(
      `/parceiro/meus-dados/cobrancas/baixar-boleto/${boletoIdentificador}`,
    ),

  listarEmpresasUsuario: () =>
    axiosInstance.get<DataResponse<Record<string, Empresa>>>('/parceiro/company/users-companies'),

  trocarEmpresa: (emp: number) => axiosInstance.get(`/parceiro/company/change/${emp}`),

  solicitarDesbloqueio: () => axiosInstance.post(`/parceiro/meus-dados/solicitar-desbloqueio`),

  gerarFaturaPrepago: () => axiosInstance.post(`/parceiro/meus-dados/gerar-fatura-prepago`, undefined),

  editarDados: (body: EditarMeusDadosBody) => axiosInstance.patch('/parceiro/meus-dados', body),

  converterTesteGratis: () => axiosInstance.patch(`/parceiro/meus-dados/converter-teste-gratis`, undefined),

  salvarFreshChatID: (id: string) => axiosInstance.patch(`/storefreshchatid/${id}`),
};

import { ZodApiBodyParser, ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

const _schemaItemEditarProdutoResponse = z.object({
  codigo: z.number(),
  codigoBarra: z.string().nullable(),
  ncm: z.string(),
  ncmNome: z.string(),
  produtoNome: z.string(),
  unidade: z.string().nullable(),
});

const _schemaEditarProdutoResponse = z.object({
  data: _schemaItemEditarProdutoResponse,
});

const _schemaEditarProdutoBody = z.object({
  ncm: z.string(),
  ncmNome: z.string(),
  produtoNome: z.string().trim().min(1, 'O campo de nome do produto não deve ser vazio'),
  funruralPf: z.number(),
  funruralPj: z.number(),
  codigoBarra: z
    .string()
    .trim()
    .transform((arg) => (arg.length === 0 ? null : arg))
    .nullable()
    .optional(),
});

export const parseEditarProdutoBody = ZodApiBodyParser.createParser(_schemaEditarProdutoBody);
export const parseEditarProdutoResponse = ZodApiResponseParser.createParser(_schemaEditarProdutoResponse);

export type EditarProdutoResponse = z.infer<typeof _schemaEditarProdutoResponse>;
export type ItemEditarProdutoResponse = z.infer<typeof _schemaItemEditarProdutoResponse>;
export type EditarProdutoBody = z.infer<typeof _schemaEditarProdutoBody>;

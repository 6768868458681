import type { Meta } from '@l_ine/core';
import { axiosInstance, type DataResponse } from 'api/axiosConfig';
import type {
  BuscarRegraEscriturarReceitaDespesa,
  BuscarRegraIgnorarDocumentos,
  EscriturarNotasDeFormaRetroativa,
  InserirConfiguracoesGeraisBody,
  InserirRegraCte,
  InserirRegraFunrural,
  InserirRegraIgnorarDocumentoBody,
  InserirRegraReceitaDespesaBody,
  ListarNotasEscrituracaoRetroativaResponse,
  ListarRegrasCte,
  ListarRegrasFunrural,
  ListarRegrasIgnorar,
  ListarRegrasReceitasDespesas,
  PendenciasNCMsResponse,
  RealizarEscrituracaoRetroativaBody,
  TipoCfop,
} from 'api/escrituracaoAutomatica/escrituracaoAutomatica.types';
import type { PlanoDeContasResponse } from 'api/planoDeContas/planoDeContas.types';
import { API_URL, API_URL_AGRONOTA } from 'api/urls';
import type { AxiosResponse } from 'axios';
import type { RawNCMItem } from 'containers/automacoes/modais/escrituracaoAutomatica.types';

export const escrituracaoAutomatica = {
  migrarParceiro: () => axiosInstance.get(`${API_URL}/parceiro/escrituracao-automatica/migrar-parceiro`),

  migrar: () => axiosInstance.get(`${API_URL_AGRONOTA}/parceiro/central-automacao/migrar`),

  listarNCMs: (params?: { search?: string; nivel?: number; codigo?: string; pageSize?: number }) =>
    axiosInstance.get<{ data: { data: RawNCMItem[]; meta: any } }>(`/parceiro/escrituracao-automatica/ncm`, {
      params: params,
    }),

  listarCFOPs: (params?: { search?: string; all?: boolean; pageSize?: number; page?: number; tipo?: TipoCfop }) =>
    axiosInstance.get<{ data: { data: string[]; meta: Meta & { last_page: number } } }>(
      `/parceiro/escrituracao-automatica/cfop`,
      {
        params,
      },
    ),

  listarRegrasIgnorarDocumentos: () =>
    axiosInstance.get<{ data: ListarRegrasIgnorar[] }>(`/parceiro/escrituracao-automatica/ignorar`),

  listarRegrasEscriturarReceitas: () =>
    axiosInstance.get<{ data: ListarRegrasReceitasDespesas[] }>(`/parceiro/escrituracao-automatica/receita`),

  listarRegrasEscriturarDespesas: () =>
    axiosInstance.get<{ data: ListarRegrasReceitasDespesas[] }>(`/parceiro/escrituracao-automatica/despesa`),

  listarRegrasEscriturarFunrural: () =>
    axiosInstance.get<{ data: ListarRegrasFunrural[] }>(`/parceiro/escrituracao-automatica/funrural`),

  listarRegrasEscriturarCte: () =>
    axiosInstance.get<{ data: ListarRegrasCte[] }>(`/parceiro/escrituracao-automatica/cte`),

  buscarRegraIgnorarDocumentos: (codigo: number) =>
    axiosInstance.get<{ data: BuscarRegraIgnorarDocumentos }>(`/parceiro/escrituracao-automatica/ignorar/${codigo}`),

  buscarRegraEscriturarReceita: (codigo: number) =>
    axiosInstance.get<{ data: BuscarRegraEscriturarReceitaDespesa }>(
      `/parceiro/escrituracao-automatica/receita/${codigo}`,
    ),

  buscarRegraEscriturarDespesa: (codigo: number) =>
    axiosInstance.get<{ data: BuscarRegraEscriturarReceitaDespesa }>(
      `/parceiro/escrituracao-automatica/despesa/${codigo}`,
    ),

  escriturarRetroativo: () => axiosInstance.get(`${API_URL_AGRONOTA}/parceiro/escriturar-retroativo`),

  inserirRegraIgnorarDocumentos: (body: InserirRegraIgnorarDocumentoBody) =>
    axiosInstance.post<AxiosResponse<{ codigoRegra: number }>>(`/parceiro/escrituracao-automatica/ignorar`, body),

  inserirRegraReceita: (body: InserirRegraReceitaDespesaBody) =>
    axiosInstance.post<AxiosResponse<{ codigoRegra: number }>>(`/parceiro/escrituracao-automatica/receita`, body),

  inserirRegraDespesa: (body: InserirRegraReceitaDespesaBody) =>
    axiosInstance.post<AxiosResponse<{ codigoRegra: number }>>(`/parceiro/escrituracao-automatica/despesa`, body),

  inserirRegraFunrural: (body: InserirRegraFunrural) =>
    axiosInstance.post(`/parceiro/escrituracao-automatica/funrural`, body),

  inserirRegraCte: (body: InserirRegraCte) => axiosInstance.post(`/parceiro/escrituracao-automatica/cte`, body),

  editarRegraIgnorarDocumentos: (codigo: number, body: InserirRegraIgnorarDocumentoBody) =>
    axiosInstance.put(`/parceiro/escrituracao-automatica/ignorar/${codigo}`, body),

  editarRegraEscriturarReceita: (codigo: number, body: InserirRegraReceitaDespesaBody) =>
    axiosInstance.put(`/parceiro/escrituracao-automatica/receita/${codigo}`, body),

  editarRegraEscriturarDespesa: (codigo: number, body: InserirRegraReceitaDespesaBody) =>
    axiosInstance.put(`/parceiro/escrituracao-automatica/despesa/${codigo}`, body),

  excluirRegraIgnorarDocumentos: (codigo: number) =>
    axiosInstance.delete(`/parceiro/escrituracao-automatica/ignorar/${codigo}`),

  excluirRegraEscriturarReceitas: (codigo: number) =>
    axiosInstance.delete(`/parceiro/escrituracao-automatica/receita/${codigo}`),

  excluirRegraEscriturarDespesas: (codigo: number) =>
    axiosInstance.delete(`/parceiro/escrituracao-automatica/despesa/${codigo}`),

  excluirRegraEscriturarFunrural: (codigo: number) =>
    axiosInstance.delete(`/parceiro/escrituracao-automatica/funrural/${codigo}`),

  excluirRegraEscriturarCte: (codigo: number) =>
    axiosInstance.delete(`/parceiro/escrituracao-automatica/cte/${codigo}`),

  listarCategoriasAGRO: (params?: { search?: string; tipo?: 1 | 2; finalidade?: 1 | 2 }) =>
    axiosInstance.get<{ data: PlanoDeContasResponse[] }>(`${API_URL_AGRONOTA}/parceiro/categorias`, {
      params: params,
    }),

  listarNotasEscrituracaoRetroativa: () =>
    axiosInstance.get<ListarNotasEscrituracaoRetroativaResponse>(
      `${API_URL_AGRONOTA}/parceiro/escrituracao-retroativa`,
    ),

  escriturarNotaDeFormaRetroativa: (body: RealizarEscrituracaoRetroativaBody) =>
    axiosInstance.post<EscriturarNotasDeFormaRetroativa>(`${API_URL_AGRONOTA}/parceiro/escrituracao-retroativa`, body),

  inserirConfiguracaoGeral: (body: InserirConfiguracoesGeraisBody) =>
    axiosInstance.post(`/parceiro/escrituracao-automatica/configuracao-geral`, body),

  pendenciasNCMs: () => axiosInstance.get<PendenciasNCMsResponse>(`/parceiro/escrituracao-automatica/pendencia`),

  buscarPdfPendenciaNCm: (codigo: number) =>
    axiosInstance.get<DataResponse<string>>(`/parceiro/escrituracao-automatica/pendencia/${codigo}`),

  marcarSugestaoComoVisualizada: () => axiosInstance.patch(`/parceiro/escrituracao-automatica/marcar-padrao`),
};

import axios from 'axios';
import type { Meta } from 'shared/types';
import { API_URL } from './urls';
import { z } from 'zod';

export const axiosInstance = axios.create({ baseURL: API_URL });

export const metaSchema = z.object({
  current_page: z.number(),
  last_page: z.number(),
  total: z.number(),
});

export const listQueryParamsSchema = z.object({
  all: z.boolean().optional(),
  infiniteScroll: z.boolean().optional(),
  page: z.number().optional(),
  pageSize: z.number().optional(),
  search: z.string().optional(),
});

export type DataResponse<T> = {
  data: T;
};

export type DataMetaExtraResponse<T> = {
  data: T;
  meta: Meta;
  extra: any;
};

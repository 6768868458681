import { LinearProgress } from '@mui/material';
import { Button } from '@s_mart/core';
import { toRem } from '@s_mart/utils';
import { regraDeTres } from 'utils/regraDeTres';
import { useEscrituracaoRetroativaV2Context } from '../escrituracaoRetroativaV2';
import { PopoverEscrituracaoRetroativaBottomProps } from './popoverEscrituracaoRetroativa.types';
import { HttpStatusCode } from 'axios';
import { useMemo } from 'react';

export const PopoverEscrituracaoRetroativaBottom = ({
  onAbrirResumoNotasEscrituradas,
}: PopoverEscrituracaoRetroativaBottomProps) => {
  const { modalsResultados, totalNotas, mutationStatus, mutationResponses } = useEscrituracaoRetroativaV2Context();

  const _todasNotasLancadasComSucesso = useMemo(() => {
    return mutationStatus === 'fulfilled' && mutationResponses.every(i => i.status === HttpStatusCode.NoContent);
  }, [mutationStatus === 'fulfilled', mutationResponses]);

  if (_todasNotasLancadasComSucesso) {
    return <Button onClick={modalsResultados.handleFecharModal}>Fechar</Button>;
  }

  if (mutationStatus === 'fulfilled') {
    return (
      <Button fullWidth sx={{ mt: 1 }} onClick={onAbrirResumoNotasEscrituradas}>
        Ver resumo
      </Button>
    );
  }

  return (
    <LinearProgress
      variant='determinate'
      value={regraDeTres(totalNotas, 100, mutationResponses.length)}
      sx={{ borderRadius: '24px!important', height: toRem(12) }}
    />
  );
};

import useAppStore from 'store/app/app';
import { api } from 'api/api';
import type { RemoverIntegracaoDialogProps } from './removerIntegracaoDialog.types';
import { useIntegradoComDominioDialogStore } from '../integradoComDominioDialog/useIntegradoComDominioDialog.store';
import { useClientesStore } from 'store/clientes/clientes';

export function useRemoverIntegracaoDialog({ handleClose }: Omit<RemoverIntegracaoDialogProps, 'open'>) {
  const { handleErrors, setLoading, setNotification } = useAppStore();
  const { dadosCliente, handleClose: handleCloseIntegradoDialog } = useIntegradoComDominioDialogStore();
  const { buscarClientes } = useClientesStore();

  async function handleRemoverIntegracao() {
    try {
      setLoading(true);

      if (dadosCliente?.codigo) {
        const { status } = await api.dominio.excluirDados(dadosCliente?.codigo);

        if (status === 204) {
          handleClose();
          handleCloseIntegradoDialog();
          setNotification({
            title: 'Domínio desvinculado com sucesso',
            message: 'Os dados de integração com a Domínio foram excluídos com sucesso',
            autoHideDuration: 5000,
          });
          buscarClientes();
        }
      } else {
        throw new Error('Não foi possível excluir os dados de integração com a Domínio');
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  }

  return {
    handleRemoverIntegracao,
  };
}

import { Typography } from '@s_mart/core';
import { useEscrituracaoRetroativaV2Context } from '../escrituracaoRetroativaV2';

export const PopoverEscrituracaoRetroativaContent = () => {
  const { totalNotas, contagemEscrituracoesComSucesso, mutationStatus, responses207 } =
    useEscrituracaoRetroativaV2Context();

  let message = `Estamos tentando lançar as notas pendentes, até agora foram ${contagemEscrituracoesComSucesso} notas de ${totalNotas}.`;

  if (mutationStatus === 'fulfilled') {
    if (responses207['ncm-sem-regra'].length > 0 && contagemEscrituracoesComSucesso === 0) {
      message = `Não conseguimos lançar nenhuma nota, preparamos um resumo para você!`;
    } else {
      message = `Revisamos todas as notas dos produtores, fizemos o lançamento de ${contagemEscrituracoesComSucesso} notas de ${totalNotas}.`;
    }
  }

  return <Typography variant='bodySM'>{message}</Typography>;
};

import { isStaging } from 'utils/isStaging';

const _isStaging = isStaging();

const API_URL = _isStaging
  ? 'https://apistaging-parceiro.apisbsistemas.com.br'
  : 'https://apiparceiro.apisbsistemas.com.br';

const URL_SIMPLESCTE = _isStaging ? 'https://staging.simplescte.com.br' : 'https://app.simplescte.com.br';
const URL_AGRONOTA = _isStaging ? 'https://staging.agronota.com.br' : 'https://app.agronota.com.br';

const API_URL_SIMPLESCTE = _isStaging ? 'https://apistaging.simplescte.com.br' : 'https://api.simplescte.com.br';
const API_URL_AGRONOTA = _isStaging ? 'https://api-beta-staging.agronota.com.br' : 'https://api-beta.agronota.com.br';

export { API_URL, API_URL_AGRONOTA, API_URL_SIMPLESCTE, URL_AGRONOTA, URL_SIMPLESCTE };

import styled from '@emotion/styled';

export const DrawerWrapper = styled.div`
  & > div {
    height: 100%;
  }
  & div > div:nth-of-type(2) > div {
    word-break: normal;
    padding: 8px 2px;

    @media (max-height: 700px) {
      padding: 4px 2px;
    }
  }
`;

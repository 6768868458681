import { create } from 'zustand';
import { PlanoDeConta, PlanoDeContasState, PlanoDeContasStore, TipoPlanoConta } from './planoDeContasStore.types';
import useAppStore from 'store/app/app';
import { api } from 'api/api';
import { parsePlanoContasMap } from './planoDeContasHelpers';
import { planoContaDespesaPai, planoContaReceitaPai } from './planoDeContas.static';
import useAuthStore from 'store/auth/auth';

const initialState: PlanoDeContasState = {
  possuiClientesComLancamentos: false,
  planoDeContasLinear: null,
  planoDeContas: new Map(),
  loading: false,
  search: '',
};

export const usePlanoDeContasStore = create<PlanoDeContasStore>(set => ({
  ...initialState,
  verificarSeTemClientesComLancamentos: async () => {
    const handleErros = useAppStore.getState().handleErrors;
    try {
      const { data: result } = await api.planoDeContas.verificarLancamentosDosClientes();
      set({
        possuiClientesComLancamentos: Boolean(result.data.possui),
      });
    } catch (error) {
      handleErros(error);
    }
  },
  listarPlanoDeContas: async params => {
    const setAppLoading = useAppStore.getState().setLoading;
    const handleErros = useAppStore.getState().handleErrors;
    const recursosDisponiveis = useAuthStore.getState()?.recursosDisponiveis;

    let _atividadeRuralParam = params?.atividadeRural;

    if (recursosDisponiveis !== null && !recursosDisponiveis.financeiro) {
      _atividadeRuralParam = 1;
    }

    try {
      set({
        loading: true,
      });
      setAppLoading(true);
      const { data: result } = await api.planoDeContas.listarPlanosDeConta({
        ...params,
        atividadeRural: _atividadeRuralParam,
      });

      const planoDeContas: PlanoDeConta[] = result.data.map(plano => ({
        ...plano,
        path: plano.codigo_categoria_pai
          ? [plano.codigo_categoria_pai.toString(), plano.codigo.toString()]
          : [plano.tipo === TipoPlanoConta.RECEITA ? '-1' : '-2', plano.codigo.toString()],
        nivel: plano.numero.split('.').length - 1,
        categorias_filho: null,
        atividadeRuralPai: plano.codigo_categoria_pai
          ? result.data.find(p => p.codigo === plano.codigo_categoria_pai)?.atividadeRural
          : undefined,
        irPai: plano.codigo_categoria_pai
          ? result.data.find(p => p.codigo === plano.codigo_categoria_pai)?.ir
          : undefined,
      }));

      const planosReceitas = planoDeContas.filter(plano => plano.tipo === TipoPlanoConta.RECEITA);
      const planosDespesas = planoDeContas.filter(plano => plano.tipo === TipoPlanoConta.DESPESA);

      const mapPlanos = new Map([
        [
          -1,
          {
            ...planoContaReceitaPai,
            categorias_filho: parsePlanoContasMap(planosReceitas),
          },
        ],
        [
          -2,
          {
            ...planoContaDespesaPai,
            categorias_filho: parsePlanoContasMap(planosDespesas),
          },
        ],
      ]);

      set({
        planoDeContas: mapPlanos,
        planoDeContasLinear: planoDeContas,
      });
    } catch (error) {
      handleErros(error);
    } finally {
      setAppLoading(false);
      set({
        loading: false,
      });
    }
  },
  setSearch: term => {
    set({
      search: term,
    });
  },
  limparStore() {
    set(initialState);
  },
}));

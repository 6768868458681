import Cookies from 'js-cookie';
import { useLayoutEffect } from 'react';
import useAuthStore from 'store/auth/auth';

export const useVerificarAutenticacao = () => {
  const [loginComToken, tratarDadosToken] = useAuthStore((state) => [state.loginComToken, state.tratarDadosToken]);

  const searchParams = new URLSearchParams(window.location.search);

  const skipValidationRoutes = ['/finalizar-contratacao', '/recuperar-senha', '/redefinir-senha', '/cadastro'];

  const validateFunction = () => {
    const pathname = window.location.pathname;

    if (skipValidationRoutes.includes(pathname)) {
      return;
    }

    if (pathname === '/redirect') {
      const tokenFromQuery = searchParams.get('token');
      if (tokenFromQuery) {
        return loginComToken({ token: tokenFromQuery });
      }
    }

    const tokenFromCookie = Cookies.get('id_token');

    if (tokenFromCookie) {
      return tratarDadosToken(tokenFromCookie);
    }
  };

  useLayoutEffect(() => {
    validateFunction();
  }, []);
};

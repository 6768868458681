import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { isStaging } from 'utils/isStaging';

export function usePostHogTrackPages() {
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    if (!isStaging()) {
      posthog?.capture('$pageview', {
        $current_url: location.pathname,
      });
    }
  }, [location, posthog]);

  return posthog;
}

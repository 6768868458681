import { Backdrop, CircularProgress } from '@s_mart/core';
import useAppStore from 'store/app/app';

type LoadingProps = {
  loading?: boolean;
};

const Loading = ({ loading }: LoadingProps) => {
  const _appLoading = useAppStore(st => st.loading);

  if (!_appLoading && !loading) return null;

  return (
    <Backdrop open style={{ zIndex: 10000 }}>
      <CircularProgress size={72} thickness={6} />
    </Backdrop>
  );
};

export default Loading;

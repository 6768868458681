import { Button, Dialog, Typography } from '@s_mart/core';
import useOnboardingAgronota from './useOnboardingAgronota';
import { Stack } from '@mui/material';
import ModalLoader from 'components/modalLoader/modalLoader';
import { colorPalette } from '@s_mart/tokens';

const OnboardingAgronota = () => {
  const { handleFinalizarOnboarding, mensagemLoadingCadastrosIniciais, loadingCadastrosIniciais } =
    useOnboardingAgronota();

  return (
    <Dialog
      open
      onClose={() => {}}
      maxWidth='md'
      fullWidth
      disableBackdropClick
      slotProps={{
        backdrop: {
          style: {
            background: colorPalette.neutral[20],
          },
        },
      }}
    >
      <Dialog.Content style={{ padding: '24px' }}>
        {loadingCadastrosIniciais && <ModalLoader message={mensagemLoadingCadastrosIniciais} />}
        <Stack gap={2} textAlign='center'>
          <div>
            <Typography variant='bodyXL'>Boas vindas ao</Typography>
            <br />
            <Typography variant='titleLG'>Painel do Parceiro</Typography>
          </div>
          <Typography variant='bodyLG'>
            Aqui você pode gerenciar a sua carteira de produtores e configurações gerais.
          </Typography>
          <Button fullWidth onClick={handleFinalizarOnboarding}>
            Vamos cadastrar o seu primeiro grupo de produtores
          </Button>
        </Stack>
      </Dialog.Content>
    </Dialog>
  );
};

export default OnboardingAgronota;

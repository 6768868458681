import { ZodApiBodyParser, ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

export const _schemaItemInserirProdutoResponse = z.object({
  codigo: z.number(),
  codigoBarra: z.string().nullable(),
  ncm: z.string(),
  ncmNome: z.string(),
  produtoNome: z.string(),
  unidade: z.string().nullable(),
});

export const _schemaInserirProdutoResponse = z.object({
  data: _schemaItemInserirProdutoResponse,
});

const _schemaInserirProdutoBody = z.object({
  codigoOrigem: z.number().nullable(),
  ncm: z.string(),
  ncmNome: z.string(),
  produtoNome: z.string().trim().min(1, 'O campo de nome do produto não deve ser vazio'),
  unidade: z.string().nullable(),
  funruralPf: z.number(),
  funruralPj: z.number(),
  codigoBarra: z
    .string()
    .trim()
    .transform((arg) => (arg.length === 0 ? null : arg))
    .nullable()
    .optional(),
});

export const parseInserirProdutoBody = ZodApiBodyParser.createParser(_schemaInserirProdutoBody);
export const parseInserirProdutoResponse = ZodApiResponseParser.createParser(_schemaInserirProdutoResponse);

export type InserirProdutoBody = z.infer<typeof _schemaInserirProdutoBody>;
export type InserirProdutoResponse = z.output<typeof _schemaInserirProdutoResponse>;
export type ItemInserirProdutoResponse = z.output<typeof _schemaItemInserirProdutoResponse>;

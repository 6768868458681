import { axiosInstance } from 'api/axiosConfig';
import type { AxiosResponse } from 'axios';

import type { DadosDominio, SalvarDadosDominioBody, VerificarChaveDominioResponse } from './dominio.types';
import { API_URL_AGRONOTA, API_URL_SIMPLESCTE } from 'api/urls';

export const dominio = {
  salvarDados: (body: SalvarDadosDominioBody) => axiosInstance.post('/parceiro/dominio', body),

  verificarChave: (chave: string) =>
    axiosInstance.get<VerificarChaveDominioResponse>(`/parceiro/dominio/verificar/${chave}`),

  buscarDados: (codigoEmpresa: number): Promise<AxiosResponse<DadosDominio>> =>
    axiosInstance.get(`/parceiro/dominio/${codigoEmpresa}`),

  sincronizarDadosSimplesCTE: (codigo: number) =>
    axiosInstance.post(`${API_URL_SIMPLESCTE}/v2/parceiro/dominio/${codigo}/sincronizar`),

  sincronizarDadosAgronota: (codigo: number) =>
    axiosInstance.get(`${API_URL_AGRONOTA}/parceiro/dominio/sincronizar/${codigo}`),

  excluirDados: (codigoEmpresa: number) => axiosInstance.delete(`/parceiro/dominio/${codigoEmpresa}`),
};

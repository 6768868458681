import { RouterProvider } from 'react-router-dom';
import { Loading } from 'components';
import { useVerificarAutenticacao } from 'hooks/useVerificarAutenticacao';
import { rotasSistema } from './static.routes';

const Router = () => {
  useVerificarAutenticacao();

  return (
    <RouterProvider
      router={rotasSistema}
      fallbackElement={<Loading loading />}
      future={{
        v7_startTransition: true,
      }}
    />
  );
};

export default Router;

export type InserirRegraIgnorarDocumentoBody = {
  cfops: string[];
  motivo: string;
  ncms: { descricao: string; ncm: string; nivel: number }[];
  todosCFOPs: 0 | 1;
  todosNCMs: 0 | 1;
};

export enum TipoCfop {
  RETORNO,
  REMESSA,
  DEVOLUCAO,
  ANULACAO,
  TRANSPORTE,
  TRANSFERENCIA,
}

export type ListarRegrasIgnorar = {
  codigo: number;
  descricaoCFOP: string;
  descricaoNCM: string;
  motivo: string | null;
  numeroCFOPs: number;
  numeroGrupoNCMs: number;
  numeroNCMs: number;
};

export type ListarRegrasReceitasDespesas = {
  codigo: number;
  descricaoNCM: string;
  nomeCategoria: string;
  atividadeRural: number;
  ir: number;
};

export type ListarRegrasFunrural = {
  categoriaDespesa: number;
  categoriaDespesaNome: string;
  codigoRegra: number;
  historicoDespesa: string;
};

export type ListarRegrasCte = {
  categoria: number;
  codigoRegra: number;
  comoEscriturar: number;
  conta: number;
  dataEscrituracao: number;
  descricao: string;
  nomeCategoria: string;
};

export type ItemInserirRegraReceitaDespesaBody = {
  descricao: string;
  ncm: string;
  nivel: number;
};

export type InserirRegraReceitaDespesaBody = {
  categoria: number;
  comoEscriturar: number;
  conta: number;
  dataEscrituracao: number;
  descricao: string;
  ncms: ItemInserirRegraReceitaDespesaBody[];
};

export type InserirRegraFunrural = {
  categoriaDespesa: number;
  historicoDespesa: string;
};

export type InserirRegraCte = {
  categoria: number;
  comoEscriturar: number;
  conta: number;
  dataEscrituracao: number;
  descricao: string;
};

export type BuscarRegraIgnorarDocumentos = {
  cfops: string[];
  codigoRegra: number;
  descricaoCFOP: string;
  descricaoNCM: string;
  motivo: string | null;
  ncms: {
    categoriaPrincipal: string;
    descricao: string;
    ncm: string;
    nivel: number;
  }[];
  qualquerCFOP: number;
  qualquerNCM: number;
};

export type BuscarRegraEscriturarReceitaDespesa = {
  categoria: number;
  codigoRegra: number;
  comoEscriturar: number;
  conta: number;
  dataEscrituracao: number;
  descricao: string;
  nomeCategoria: string;
  ncms: {
    categoriaPrincipal: string;
    descricao: string;
    ncm: string;
    nivel: number;
  }[];
};

export enum OrigemDocumentoEscrituracaoRetroativa {
  MONITOR_FISCAL,
  NOTAS_EMITIDAS,
}

export type RealizarEscrituracaoRetroativaBody = {
  codigoCliente: number;
  codigoNota: number;
  origem: OrigemDocumentoEscrituracaoRetroativa;
};

export type ListarNotasEscrituracaoRetroativaResponse = {
  data: Array<{
    codigoCliente: number;
    nomeCliente: string;
    codigosNotas: number[];
    origem: OrigemDocumentoEscrituracaoRetroativa;
  }>;
};

export enum TipoNota {
  Entrada = 0,
  Saida = 1,
}

export type EscriturarNotasDeFormaRetroativa207DataMesFechado = {
  tipoErro: 'mes-fechado';
  tipoNota: TipoNota;
  data: string;
};

export type EscriturarNotasDeFormaRetroativa207DataOmie = {
  tipoErro: 'omie';
  tipoNota: TipoNota;
  ncm: string;
};

export type EscriturarNotasDeFormaRetroativa207DataNcmSemRegra = {
  tipoErro: 'ncm-sem-regra';
  tipoNota: TipoNota;
  ncm: string;
};

export type EscriturarNotasDeFormaRetroativa207DataPropriedadeSemIe = {
  tipoErro: 'nota-sem-ie';
  tipoNota: TipoNota;
};

export type EscriturarNotasDeFormaRetroativa207DataNaoMapeado = {
  tipoErro: 'nao-mapeado';
  tipoNota: TipoNota;
  mensagem: string;
};

// Caso o retorno seja 207 (Multi-Status) é retornado o tipo de erro, e cada tipo de erro possuem schemas diferentes
export type EscriturarNotasDeFormaRetroativa207 = {
  data:
    | EscriturarNotasDeFormaRetroativa207DataMesFechado
    | EscriturarNotasDeFormaRetroativa207DataNcmSemRegra
    | EscriturarNotasDeFormaRetroativa207DataOmie
    | EscriturarNotasDeFormaRetroativa207DataPropriedadeSemIe
    | EscriturarNotasDeFormaRetroativa207DataNaoMapeado;
};

export type EscriturarNotasDeFormaRetroativa204 = undefined;

// Retorno pode ser 207 (Multi-Status) ou 204 (No Content)
export type EscriturarNotasDeFormaRetroativa =
  | EscriturarNotasDeFormaRetroativa204
  | EscriturarNotasDeFormaRetroativa207;

export type InserirConfiguracoesGeraisBody = {
  comoEscriturar: number;
  conta: number;
};

// -- pendenciasNCMs

export type ItemPendenciasNCMsResponse = {
  grupoDescricao: string;
  grupoNCM: string;
  ncms: {
    codigo: number;
    descricao: string;
    naturezaOperacao: string;
    ncm: string;
    possuiDanfe: number;
    quantidadeDocumentos: number;
  }[];
};
export type PendenciasNCMsResponse = {
  data: null | {
    receitas: ItemPendenciasNCMsResponse[] | null;
    despesas: ItemPendenciasNCMsResponse[] | null;
  };
};

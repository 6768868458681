import useSistema from 'hooks/useSistema';
import { useCallback } from 'react';

export function useConteudoPrimeiroPasso() {
  const { whatsApp, name: sistemaNome } = useSistema();

  const textoSistema = sistemaNome === 'SimplesCTe' ? 'todos os CTes serão enviados' : 'todas as NFes serão enviadas';

  const handleOpenWhatsApp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${whatsApp}&text=Olá, preciso de ajuda para integrar com a Domínio.`,
      '_blank'
    );
  }, []);

  return {
    textoSistema,
    handleOpenWhatsApp,
  };
}

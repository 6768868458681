import { createSearchParams, type NavigateFunction } from 'react-router-dom';
import type { Perfil } from 'store/auth/auth.types';
import { lineBoxUSD, lineCog, lineCommentAlt, lineLandmark, lineUsers } from '@s_mart/light-icons';
import { lineWhatsApp } from '@s_mart/regular-icons';
import { lineInfoCircle } from '@s_mart/solid-icons';
import { freshChat } from 'utils/freshchat';
import { primaryColor } from 'styles/themes/theme';
import { sistemas } from 'constants/sistemas';
import type React from 'react';
import type { AppBarProfileAction } from '@l_ine/core';

export const appBarProfileActionsAgronota = (
  navigate: NavigateFunction,
): Array<Omit<AppBarProfileAction, 'label'> & { label: string }> => [
  {
    label: 'Minha conta',
    icon: lineLandmark,
    onClick: () =>
      navigate({
        pathname: '/config/minha-conta',
        search: `?${createSearchParams({
          tab: 'minhaEmpresa',
        })}`,
      }),
  },
  {
    label: 'Meu plano',
    icon: lineBoxUSD,
    onClick: () =>
      navigate({
        pathname: '/config/minha-conta',
        search: `?${createSearchParams({
          tab: 'meuPlano',
        })}`,
      }),
  },
  {
    label: 'Usuários do escritório',
    icon: lineUsers,
    onClick: () => navigate('/config/usuarios'),
    separator: true,
  },
  {
    label: 'Configurações',
    icon: lineCog,
    onClick: () => navigate('/config'),
  },
];

export const appBarProfileActions = (navigate: NavigateFunction): AppBarProfileAction[] => [
  {
    label: 'Minha conta',
    icon: lineLandmark,
    onClick: () =>
      navigate({
        pathname: '/config/minha-conta',
        search: `?${createSearchParams({
          tab: 'minhaEmpresa',
        })}`,
      }),
  },
  {
    label: 'Meu plano',
    icon: lineBoxUSD,
    onClick: () =>
      navigate({
        pathname: '/config/minha-conta',
        search: `?${createSearchParams({
          tab: 'meuPlano',
        })}`,
      }),
  },
  {
    label: 'Usuários',
    icon: lineUsers,
    onClick: () => navigate('/config/usuarios'),
    separator: true,
  },
  {
    label: 'Configurações',
    icon: lineCog,
    onClick: () => navigate('/config'),
  },
];

const dominio = window.location.hostname.split('.')[1];

const infoSistema = sistemas[dominio];

export const opcoesAjudaAgro = [
  {
    label: 'Central de ajuda',
    icon: lineInfoCircle,
    color: primaryColor,
    onClick: () => {
      window.open('https://ajuda.agronota.com.br/', '_blank');
    },
  },
  {
    label: 'WhatsApp',
    icon: lineWhatsApp,
    onClick: () => {
      window.open(`https://api.whatsapp.com/send?phone=${infoSistema.whatsApp}`, '_blank');
    },
  },
  {
    label: 'AnyDesk',
    imgPath: '/static/svgs/anyDeskIcon.svg',
    onClick: () => {
      window.open('https://anydesk.com/pt/downloads', '_blank');
    },
  },
];

export const opcoesAjudaSimplesCte = (profile: Perfil) => [
  {
    label: 'WhatsApp',
    icon: lineWhatsApp,
    onClick: () => {
      window.open(`https://api.whatsapp.com/send?phone=${infoSistema.whatsApp}`, '_blank');
    },
  },
  {
    label: 'Chat',
    icon: lineCommentAlt,
    onClick: () => {
      if (!infoSistema.freshchat) {
        window.open(`https://api.whatsapp.com/send?phone=${infoSistema.whatsApp}`, '_blank');
      } else {
        freshChat(profile);
      }
    },
  },
];

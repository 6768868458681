import styled from '@emotion/styled';

export const BannerContainer = styled.div`
  position: relative;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background-color: ${({ theme }) => theme.palette.primary.main}40;
`;

export const LeftIndicator = styled.div`
  width: 6px;

  background-color: ${({ theme }) => theme.palette.primary.main};

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`;

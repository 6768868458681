import { axiosInstance } from 'api/axiosConfig';
import type { LoginPayload, LoginResponse, SetupResponse, SignUpBody } from './auth.types';

export const auth = {
  login: (body: LoginPayload) => axiosInstance.post<LoginResponse>('/auth/login', body),

  logout: () => axiosInstance.get('/parceiro/logout'),

  signup: (body: SignUpBody) => axiosInstance.post('/auth/register', body),

  buscarSetup: () => axiosInstance.get<{ data: SetupResponse }>('/parceiro/setup'),

  completarRegistro: (body: any) => axiosInstance.post('/auth/registerpasswordparceiro', body),

  verificarTokenCompletarRegistro: (data: { token: string; email: string }) =>
    axiosInstance.get(`/auth/registerparceiro/verify/${data.token}?email=${data.email}`),

  enviarEmailRecuperarSenha: (body: { email: string }) => axiosInstance.post('/auth/password/create', body),

  editarSenha: (body: any) => axiosInstance.put('/parceiro/password', body),

  redefinirSenha: (body: { password: string; confirm_password: string }) =>
    axiosInstance.post(`/auth/password/reset`, body),

  verificarTokenConvite: (token: string) => axiosInstance.get(`/auth/invites/find/${token}`),

  verificarTokenRedefinirSenha: (token: string) => axiosInstance.get(`/auth/password/find/${token}`),

  buscarTokenRedirect: (payload: number, sistema: number) =>
    axiosInstance.get(`/parceiro/systemaccess/${payload}/${sistema}`),
};

import { useState } from 'react';

export function useBannerContratacao() {
  const [contratarPlanoDialogOpen, _setContratarPlanoDialogOpen] = useState(false);

  const handleOpenDialogContratarPlano = () => {
    _setContratarPlanoDialogOpen(true);
  };

  const handleCloseContratarPlanoDialog = () => {
    _setContratarPlanoDialogOpen(false);
  };

  return {
    contratarPlanoDialogOpen,
    handleOpenDialogContratarPlano,
    handleCloseContratarPlanoDialog,
  };
}

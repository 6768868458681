import { TipoNota } from 'api/escrituracaoAutomatica/escrituracaoAutomatica.types';
import { HttpStatusCode } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  type DadosEtapasModal,
  EtapasModal,
  type StateEtapaModalNCMsSemRegras,
  type StateEtapaModalNCMsSemRegrasValue,
  type StateEtapaModalResumoDocumentos,
  type UseModalsEscrituracaoRetroativaState,
} from './useModalsEscrituracaoRetroativaState.types';
import useAuthStore from 'store/auth/auth';
import { EnumAutomacaoAgronota } from 'constants/configuracoes';

export function useModalsEscrituracaoRetroativaState({
  mutationResponses,
  responses207,
  mutationStatus,
  mapClientes,
  resetarEscrituracaoRetroativa,
}: UseModalsEscrituracaoRetroativaState) {
  const [etapa, _setEtapa] = useState<EtapasModal>(EtapasModal.ModalFechada);
  const [dadosEtapas, _setDadosEtapas] = useState<DadosEtapasModal>({
    [EtapasModal.ResumoDocumentos]: {},
    [EtapasModal.ModalNCMsPendentes]: null,
  });

  const possuiAutomacaoSAAS = useAuthStore(
    (s) => s.configuracoes?.automacaoAgronota === EnumAutomacaoAgronota.CentralFinanceira,
  );

  const primeiraEtapa = etapa === EtapasModal.ResumoDocumentos;
  const ultimaEtapa = possuiAutomacaoSAAS
    ? etapa === EtapasModal.ResumoDocumentos
    : etapa === EtapasModal.ModalNCMsPendentes;

  const resetarModalEscrituracaoRetroativaState = () => {
    _setEtapa(EtapasModal.ModalFechada);
    _setDadosEtapas({
      [EtapasModal.ResumoDocumentos]: {},
      [EtapasModal.ModalNCMsPendentes]: null,
    });
  };

  const handleFecharModal = () => {
    resetarEscrituracaoRetroativa();

    _setEtapa(EtapasModal.ModalFechada);
  };

  const handleProximaEtapa = () => {
    if (etapa === EtapasModal.ModalFechada) {
      _setEtapa(EtapasModal.ResumoDocumentos);
    } else if (
      etapa === EtapasModal.ResumoDocumentos &&
      dadosEtapas[EtapasModal.ModalNCMsPendentes] !== null &&
      !possuiAutomacaoSAAS
    ) {
      _setEtapa(EtapasModal.ModalNCMsPendentes);
    } else {
      handleFecharModal();
    }
  };

  const handleVoltarEtapa = () => {
    if (etapa === EtapasModal.ResumoDocumentos) {
      if (dadosEtapas[EtapasModal.ModalNCMsPendentes] !== null) {
        handleFecharModal();
      } else {
        _setEtapa(EtapasModal.ModalFechada);
      }
    } else if (etapa === EtapasModal.ModalNCMsPendentes) {
      _setEtapa(EtapasModal.ModalFechada);
    }
  };
  const _dadosNotasPorProdutores = useCallback(() => {
    return mutationResponses.reduce<StateEtapaModalResumoDocumentos>((acc, mutationResponse) => {
      const _bodyJSON = mutationResponse.config.data;

      // Se não existir o body, pula para o próximo
      if (!_bodyJSON) {
        return acc;
      }

      // A tipagem do axios diz que é um objeto mas na verdade é um JSON, por isso o cast de tipo
      const _bodyData = JSON.parse(_bodyJSON as unknown as string) as typeof _bodyJSON;

      const _nomeCliente = mapClientes.get(_bodyData.codigoCliente);

      // Se não existir um cliente para o código, pula para o próximo
      if (!_nomeCliente) {
        return acc;
      }

      let _notaEscriturada = mutationResponse.status === HttpStatusCode.NoContent;

      _notaEscriturada ||=
        mutationResponse.status === HttpStatusCode.MultiStatus && mutationResponse.data?.data.tipoErro === 'omie';

      const _baseDadosCliente = {
        produtor: _nomeCliente,
        quantidadeNotasEscrituradasOuIgnoradas: 0,
        quantidadeNotasNaoEscrituradas: 0,
      };

      // Obtem dados do cliente, Se não existir o cliente, cria base das informações
      const _draftDadosCliente = acc[_bodyData.codigoCliente] ?? _baseDadosCliente;
      if (_notaEscriturada) {
        _draftDadosCliente.quantidadeNotasEscrituradasOuIgnoradas++;
      } else {
        _draftDadosCliente.quantidadeNotasNaoEscrituradas++;
      }

      acc[_bodyData.codigoCliente] = _draftDadosCliente;

      return acc;
    }, {});
  }, [mutationStatus]);

  const _dadosNotasPorNCMs = useCallback(() => {
    if (!responses207['ncm-sem-regra'].length) {
      return null;
    }

    return responses207['ncm-sem-regra'].reduce<StateEtapaModalNCMsSemRegras>((acc, ncm) => {
      const _baseDadosNcmSemRegra: StateEtapaModalNCMsSemRegrasValue = {
        ncm: ncm.ncm,
        tipoNota: ncm.tipoNota,
        tipoNotaLabel: ncm.tipoNota === TipoNota.Entrada ? 'Receita' : 'Despesa',
        quantidadeNotasNaoEscrituradas: 0,
      };

      const _draftDadosNcmSemRegra = acc[ncm.ncm] ?? _baseDadosNcmSemRegra;

      _draftDadosNcmSemRegra.quantidadeNotasNaoEscrituradas++;

      acc[ncm.ncm] = _draftDadosNcmSemRegra;

      return acc;
    }, {});
  }, [mutationStatus]);

  useEffect(() => {
    // Se as requisições não tiverem finalizadas, não faz nada
    if (mutationStatus !== 'fulfilled') {
      return;
    }

    const _notasPorProdutores = _dadosNotasPorProdutores();
    const _notasPorNCMs = _dadosNotasPorNCMs();

    _setDadosEtapas({
      [EtapasModal.ResumoDocumentos]: _notasPorProdutores,
      [EtapasModal.ModalNCMsPendentes]: _notasPorNCMs,
    });

    // Só possui mutationStatus como dependência pois só precisamos executar o useEffect quando as requisições terminarem
  }, [mutationStatus]);

  return {
    etapa,
    dadosEtapas,
    handleFecharModal,
    handleVoltarEtapa,
    handleProximaEtapa,
    resetarModalEscrituracaoRetroativaState,
    primeiraEtapa,
    ultimaEtapa,
  };
}

import React, { FC, memo } from 'react';
import type { IPageHeaderProps } from './pageHeader.types';

import { Title } from './title/title';
import { Actions } from './actions/actions';
import { Grid } from '@l_ine/core';

const PageHeaderWithoutMemo: FC<IPageHeaderProps> = ({ children }) => {
  const title = React.useMemo(() => {
    return React.Children?.toArray(children)?.filter((child: any) => child?.type === Title);
  }, [children]);

  const actions = React.useMemo(() => {
    return React.Children?.toArray(children)?.find((child: any) => child?.type === Actions);
  }, [children]);

  return (
    <Grid container justifyContent='space-between'>
      <Grid item>{title}</Grid>
      <Grid item>{actions}</Grid>
    </Grid>
  );
};

const PageHeader = memo(PageHeaderWithoutMemo) as React.NamedExoticComponent<IPageHeaderProps> & {
  Title: React.ComponentType<React.ComponentProps<typeof Title>>;
  Actions: React.ComponentType<React.ComponentProps<typeof Actions>>;
};

PageHeader.Title = Title;
PageHeader.Actions = Actions;

export default PageHeader;

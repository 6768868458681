import { ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

const _schemaCnpjParam = z.string().transform((value) => value.replace(/\D/g, ''));

const _schemaItemBuscarCnpjResponse = z.object({
  atividade_principal: z.array(z.object({ code: z.string() })),
  atividades_secundarias: z.array(z.unknown()).nullable(),
  socios: z.array(z.unknown()).nullable(),
  cnpj: z.string().nullable(),
  nome: z.string().nullable(),
  fantasia: z.string().nullable(),
  telefone: z.string().nullable(),
  email: z.string().nullable(),
  nm_cidade_exterior: z.unknown().nullable(),
  pais: z.string().nullable(),
  municipio: z.string().nullable(),
  municipio_codigo: z.number().nullable(),
  uf: z.string().nullable(),
  cep: z.string().nullable(),
  logradouro: z.string().nullable(),
  numero: z.string().nullable(),
  bairro: z.string().nullable(),
  complemento: z.string().nullable(),
  porte: z.string().nullable(),
  abertura: z.string().nullable(),
  natureza_juridica_codigo: z.string().nullable(),
  tipo: z.string().nullable(),
  situacao: z.string().nullable(),
  data_situacao_cadastral: z.string().nullable(),
  motivo_situacao: z.string().nullable(),
  situacao_especial: z.unknown().nullable(),
  data_situacao_especial: z.unknown().nullable(),
  capital_social: z.string().nullable(),
  rntrc: z.string().nullable(),
  rntrc_data_cadastro: z.unknown().nullable(),
  rntrc_data_validade: z.unknown().nullable(),
  rntrc_situacao: z.unknown().nullable(),
  antt_taf: z.unknown().nullable(),
  opc_mei: z.unknown().nullable(),
  opc_simples: z.unknown().nullable(),
  data_opc_simples: z.unknown().nullable(),
  data_exc_simples: z.unknown().nullable(),
  inscricao_suframa: z.unknown().nullable(),
  ie: z.string().nullable(),
  situacao_ie: z.unknown().nullable(),
  tipo_inscricao: z.unknown().nullable(),
  informacao_ie_como_destinatario: z.unknown().nullable(),
  regime_tributacao: z.unknown().nullable(),
  obriga_pagamento_eletronico: z.unknown().nullable(),
  ies: z
    .array(
      z.object({
        ie: z.string(),
      }),
    )
    .nullable(),
});

const _schemaBuscarCnpjResponse = z.object({
  data: _schemaItemBuscarCnpjResponse,
});

export type BuscarCnpjResponse = z.infer<typeof _schemaBuscarCnpjResponse>;
export type ItemBuscarCnpjResponse = z.infer<typeof _schemaItemBuscarCnpjResponse>;
export type BuscarCnpjParamCnpj = z.infer<typeof _schemaCnpjParam>;

export const parseBuscarCnpjResponse = ZodApiResponseParser.createParser(_schemaBuscarCnpjResponse);

import { clarity } from 'react-microsoft-clarity';
import { isStaging } from 'utils/isStaging';
import useSistema from './useSistema';
import useAuthStore from 'store/auth/auth';

const useClarity = () => {
  const { code } = useSistema();
  const { perfil } = useAuthStore();

  const clarityIds: Record<number, string> = {
    // SIMPLESCTE
    1: 'hqeshseny3',
    // AGRONOTA
    2: 'hqep0viwh2',
  };

  const init = () => {
    if (!isStaging() && clarityIds[code] && !clarity.hasStarted()) {
      clarity.init(clarityIds[code]);
    }
    if (clarity.hasStarted() && perfil.emp !== null) {
      clarity.identify(String(perfil.emp), {});
    }
  };

  const setTag = (tag: string[]) => {
    if (clarity.hasStarted() && tag.length === 2) {
      clarity.setTag(tag[0], tag[1]);
    }
  };

  return { init, setTag };
};

export default useClarity;

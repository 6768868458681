import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { TipoPessoa } from 'containers/clientes/modalNovoCliente/modalNovoCliente.types';
import { z } from 'zod';

const _schemaBuscarContribuinteParams = z.object({
  uf: z.string(),
  cpf: z.string().optional(),
  cnpj: z.string().optional(),
  ie: z.string().optional(),
  ieProdutor: z.string().optional(),
});

const _schemaItemBuscarContribuinteResponse = z.object({
  atividadeEconomica: z.string(),
  bairro: z.string(),
  cep: z.string(),
  codigoAtividadeEconomica: z.number().nullable(),
  codigoCidade: z.number(),
  codigoEstado: z.number(),
  complemento: z.string().nullable(),
  cpfCnpj: z.number(),
  dataInclusaoMei: z.string().nullable(),
  dataInclusaoSimplesNacional: z.string().nullable(),
  email: z.string().nullable(),
  enquadradoMei: z.number(),
  ie: z.string(),
  naturezaJuridica: z.number().nullable(),
  nomeCidade: z.string(),
  nomeFantasia: z.string(),
  numero: z.string(),
  optanteSimplesNacional: z.number(),
  porte: z.string().nullable(),
  razaoSocial: z.string(),
  rua: z.string(),
  /**
   * Situação Cadastral:
   *
   * 0 - Nulo
   *
   * 1 - Ativo
   *
   * 2 - Suspenso
   *
   * 3 - Inapto
   *
   * 4 - Baixado
   */
  situacaoCadastral: z.number(),
  telefone: z.string().nullable(),
  tipoPessoa: z.nativeEnum(TipoPessoa),
  uf: z.string(),
});

const _schemaBuscarContribuinteResponse = z.object({
  data: _schemaItemBuscarContribuinteResponse.array().nullable(),
});

export const parseBuscarContribuinteResponse = ZodApiResponseParser.createParser(_schemaBuscarContribuinteResponse);
export const parseBuscarContribuinteParams = ZodApiParamsParser.createParser(_schemaBuscarContribuinteParams);

export type BuscarContribuinteParams = z.infer<typeof _schemaBuscarContribuinteParams>;
export type ItemBuscarContribuinteResponse = z.infer<typeof _schemaItemBuscarContribuinteResponse>;
export type BuscarContribuinteResponse = z.infer<typeof _schemaBuscarContribuinteResponse>;

export enum EnumSistemas {
  SIMPLESCTE = 1,
  AGRONOTA = 2,
  ICFC = 4,
  SOLARFY = 6,
}

export type Sistema = {
  code: EnumSistemas;
  name: string;
  freshchat?: string;
  remote_assistance?: string;
  whatsApp: string;
};

const simplescte = {
  name: 'SimplesCTe',
  code: EnumSistemas.SIMPLESCTE,
  freshchat: '0c801186-6f47-49da-8175-eb6a35469e35',
  remote_assistance: 'https://get.teamviewer.com/simplescte',
  whatsApp: '54991848116',
};

const agronota = {
  name: 'Agronota',
  code: EnumSistemas.AGRONOTA,
  whatsApp: '5436984215',
};

const icfc = {
  name: 'iCFC',
  code: EnumSistemas.ICFC,
  freshchat: '7cabdbae-001d-4120-9d43-2ee169ba132b',
  whatsApp: '54991848116',
};

const solarfy = {
  name: 'Solarfy',
  code: EnumSistemas.SOLARFY,
  freshchat: '3e8f8b96-65a6-4de1-8e34-1346ee42ff9a',
  remote_assistance: 'https://get.teamviewer.com/solarfy',
  whatsApp: '549984346205',
};

export const sistemas: Record<string | EnumSistemas, Sistema> = {
  [EnumSistemas.SIMPLESCTE]: simplescte,
  [EnumSistemas.AGRONOTA]: agronota,
  [EnumSistemas.ICFC]: icfc,
  [EnumSistemas.SOLARFY]: solarfy,
  simplescte,
  agronota,
  icfc,
  solarfy,
} as const;

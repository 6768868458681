import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import useAuthStore from 'store/auth/auth';
import type { Perfil } from 'store/auth/auth.types';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const _isProduction = import.meta.env.MODE === 'production';

const configureSentryScope = (user: Perfil) => {
  if (!_isProduction) return;

  if (user?.emp) {
    Sentry.setUser({
      ip_address: '{{auto}}',
      id: String(user.emp),
      email: user.email ?? '',
      username: user.nome ?? '',
      empNome: user.razao_social ?? '',
    });
  }
};

const useSentry = () => {
  const perfil = useAuthStore((state) => state.perfil);
  useEffect(() => {
    if (perfil) {
      configureSentryScope(perfil);
    }
  }, [perfil]);

  useEffect(() => {
    if (_isProduction && typeof Sentry.init === 'function') {
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,

        environment: import.meta.env.MODE,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.captureConsoleIntegration({ levels: ['error'] }),
        ],
        release: import.meta.env.VITE_SENTRY_RELEASE,

        tracesSampleRate: 0.2,
        normalizeDepth: 10,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        beforeSend(event) {
          const exceptionValue = event.exception?.values?.[0];
          /**
           * Erros ignorados no sentry
           * `Load failed` acontece normalmente quando o cache do navegador ainda contem uma versão
           *  da build
           * `AxiosError` requisições falhidas que já são tratadas dentro do sistema
           */
          if (exceptionValue?.value === 'Load failed') {
            return null;
          }
          if (exceptionValue?.type === 'AxiosError' || exceptionValue?.value?.includes('AxiosError')) {
            return null;
          }

          return event;
        },
      });
    }
  }, [_isProduction]);
};

export default useSentry;

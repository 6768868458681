import { useEffect } from 'react';

import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import useSistema from 'hooks/useSistema';
import { isStaging } from 'utils/isStaging';
import { useCobrancasStore } from 'store/cobrancas/cobrancasStore';
import { usePathname } from '@s_mart/hooks';
import { ModalBoletoSituacao } from 'store/app/app.types';
import useUsuarioSBSistemas from 'hooks/useUsuarioSBSistemas';
import { EnumSistemas } from 'constants/sistemas';

dayjs.extend(relativeTime);

export function usePrivate() {
  const [mostrarOnboarding, modalBoleto, setMostrarOnboarding, setModalBoleto] =
    useAppStore((state) => [
      state.mostrarOnboarding,
      state.modalBoleto,
      state.setMostrarOnboarding,
      state.setModalBoleto,
    ]);

  const pathname = usePathname();
  const { code } = useSistema();
  const usuarioSb = useUsuarioSBSistemas();

  const [
    token,
    configuracoes,
    configuracoesUsuario,
    perfil,
    meusDados,
    bloqueado,
  ] = useAuthStore((state) => [
    state.token,
    state.configuracoes,
    state.configuracoesUsuario,
    state.perfil,
    state.meusDados,
    state.bloqueado,
  ]);

  const { boletosEmAberto, boletosVencidos } = useCobrancasStore();

  // validação modal boleto
  useEffect(() => {
    if (pathname.includes('minha-conta') || usuarioSb) {
      return;
    }
    if (bloqueado === null) {
      return;
    }

    if (bloqueado) {
      if (boletosVencidos.length === 1) {
        if (
          configuracoesUsuario?.boletosMarcadosComoPago?.[
            boletosVencidos[0].identificador
          ]
        ) {
          if (
            dayjs(
              configuracoesUsuario.boletosMarcadosComoPago[
                boletosVencidos[0].identificador
              ]
            ).isBefore(dayjs().add(-1, 'day'))
          ) {
            setModalBoleto(ModalBoletoSituacao.PAGAMENTO_NAO_IDENTIFICADO);
          }
        } else {
          setModalBoleto(ModalBoletoSituacao.UNICO_BOLETO_VENCIDO);
        }
      }
      if (boletosVencidos.length > 1) {
        setModalBoleto(ModalBoletoSituacao.VARIOS_BOLETOS_VENCIDOS);
      }
      return;
    }
    if (
      boletosEmAberto.length === 1 &&
      (configuracoesUsuario?.boletosVisualizados === undefined ||
        configuracoesUsuario?.boletosVisualizados[
          boletosEmAberto[0].identificador
        ] === undefined)
    ) {
      setModalBoleto(ModalBoletoSituacao.BOLETO_DISPONIVEL);
    }
  }, [
    boletosEmAberto,
    boletosVencidos,
    configuracoesUsuario,
    bloqueado,
    usuarioSb,
  ]);

  // validação do onboarding Agronota
  useEffect(() => {
    if (code !== EnumSistemas.AGRONOTA) {
      return;
    }

    if (configuracoes?.onboarding === 0) {
      if (isStaging()) {
        setMostrarOnboarding(true);
      } else if (
        (perfil?.emp !== null && perfil.emp > 9801) ||
        perfil.emp === 4962 ||
        perfil.emp === 8416
      ) {
        setMostrarOnboarding(true);
      }
    }
  }, [configuracoes, code, perfil]);

  return {
    mostrarOnboarding,
    token,
    meusDados,
    modalBoleto,
  };
}

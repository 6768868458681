export const CLARITY_TAGS = {
  RESUMO: {
    REGRAS_SUGESTOES_ABRIR_MODAL: ['resumo', 'abrir modal sugestoes'],
    REGRAS_SUGESTOES_INCLUIR: ['resumo', 'incluir sugestões nas regras'],
    REGRAS_SUGESTOES_IGNORAR: ['resumo', 'ignorar sugesões'],
    REGRAS_SUGESTOES_ABRIR_MODAL_PELO_AVISO: ['resumo', 'abrir modal sugestoes pelo aviso'],
  },
  CLIENTES: {
    NOVO_CLIENTE: ['clientes', 'novo cliente'],
  },
  PRODUTOS: {
    NOVO_PRODUTO: ['produtos', 'novo produto'],
    EDITAR_PRODUTO: ['produtos', 'editar produto'],
    EXCLUIR_PRODUTO: ['produtos', 'excluir produto'],
  },
  SIMULADOR_IR: {
    SELECIONAR_PRODUTOR: ['simulador IR', 'selecionar produtor'],
    SELECIONAR_ANO: ['simulador IR', 'selecionar ano'],
    NOVO_PRODUTOR: ['simulador IR', 'novo produtor'],
    EDITAR_PRODUTOR: ['simulador IR', 'editar produtor'],
    SIMULAR_IMPOSTO: ['simulador IR', 'simular imposto'],
    ENVIAR_POR_EMAIL: ['simulador IR', 'enviar por email'],
    IMPRIMIR: ['simulador IR', 'imprimir'],
    VOLTAR_A_TELA_INICIAL: ['simulador IR', 'voltar a tela inicial'],
  },
  CATEGORIAS: {
    NOVA_CATEGORIA: ['categorias', 'nova categoria'],
    NOVA_SUBCATEGORIA: ['categorias', 'nova subcategoria'],
    EDITAR: ['categorias', 'editar'],
    EXCLUIR: ['categorias', 'excluir'],
  },
  ESCRITURACAO_AUTOMATICA: {
    NOVA_REGRA_IGNORAR: ['escrituração automática', 'nova regra ignorar'],
    NOVA_REGRA_RECEITAS: ['escrituração automática', 'nova regra receitas'],
    NOVA_REGRA_DESPESAS: ['escrituração automática', 'nova regra despesas'],
    NOVA_REGRA_FUNRURAL: ['escrituração automática', 'nova regra funrural'],
    NOVA_REGRA_CTE: ['escrituração automática', 'nova regra cte'],
    EDITAR_REGRA_IGNORAR: ['escrituração automática', 'editar regra ignorar'],
    EDITAR_REGRA_RECEITAS: ['escrituração automática', 'editar regra receitas'],
    EDITAR_REGRA_DESPESAS: ['escrituração automática', 'editar regra despesas'],
    EDITAR_REGRA_FUNRURAL: ['escrituração automática', 'editar regra funrural'],
    EDITAR_REGRA_CTE: ['escrituração automática', 'editar regra cte'],
    ESCRITURACAO_RETROATIVA: ['escrituração automática', 'escriturar de forma retroativa'],
  },
  CONFIGURACOES: {
    USUARIOS: ['configurações', 'usuários'],
    CONVIDAR_USUARIO: ['configurações', 'convidar usuário'],
    MEU_PLANO: ['configurações', 'meu plano'],
    MINHA_CONTA: ['configurações', 'minha conta'],
    LANCAMENTOS: ['configurações', 'lançamentos'],
  },
  CONVITES: {
    CADASTRAR_USUARIO: ['convites', 'cadastrar usuário'],
    FINALIZAR_CONTRATACAO: ['convites', 'finalizar contratação'],
  },
  NATUREZAS: {
    CRIAR: ['naturezas', 'nova natureza'],
    EDITAR: ['naturezas', 'editar natureza'],
  },
  NOVIDADES: {
    ABRIR: ['novidades', 'abrir'],
    REDIRECT: ['novidades', 'redirect'],
  },
};

import * as React from 'react';

import { ConfirmationDialog } from 'components/confirmation/confirmation';
import type { ConfirmationOptions } from 'components/confirmation/confirmation.types';

type PromiseOptions<T = unknown> =
  | undefined
  | {
      rejectReturn?: T;
      resolveReturn?: T;
    };

type PromiseResponse = <T = unknown>(options: ConfirmationOptions, promiseOptions?: PromiseOptions<T>) => Promise<T>;

export const ConfirmationServiceContext = React.createContext<PromiseResponse>(Promise.reject);

export const ConfirmationServiceProvider = ({ children }: any) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);
  const [_promiseOptions, _setPromiseOptions] = React.useState<PromiseOptions>(undefined);

  const awaitingPromiseRef = React.useRef<{
    resolve: (resReturn: any) => void;
    reject: (rejReturn: any) => void;
  }>();

  function openConfirmation<T = unknown>(options: ConfirmationOptions, promiseOptions?: PromiseOptions<T>) {
    setConfirmationState(options);
    _setPromiseOptions(promiseOptions);
    return new Promise<T>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject(_promiseOptions?.rejectReturn);
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(_promiseOptions?.resolveReturn);
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>{children}</ConfirmationServiceContext.Provider>

      {confirmationState !== null ? (
        <ConfirmationDialog
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          description={confirmationState.description}
          title={confirmationState.title}
          variant={confirmationState.variant}
          catchOnCancel={confirmationState.catchOnCancel}
          primaryActionText={confirmationState.primaryActionText}
          secondaryActionText={confirmationState.secondaryActionText}
          DialogProps={confirmationState.DialogProps}
        />
      ) : null}
    </>
  );
};

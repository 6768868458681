// usádo any pois não é possível saber o tipo de erro retornado do backend
const getXMotivo = (error: any): string | null => {
  if ('erro' in error) {
    if ('extra' in error.erro) {
      return error.erro.extra.xMotivo || null;
    }
  }

  return null;
};

export default getXMotivo;

import { captureException } from '@sentry/browser';
import type { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash-es';

import type { z } from 'zod';

enum EnumZodApiGroup {
  RESPONSE_OPTIONAL,
  RESPONSE,
  BODY,
  PARAMS,
}

const GROUP_TITLE = {
  [EnumZodApiGroup.RESPONSE_OPTIONAL]: 'Response (optional)',
  [EnumZodApiGroup.RESPONSE]: 'Response',
  [EnumZodApiGroup.BODY]: 'Body',
  [EnumZodApiGroup.PARAMS]: 'Params',
};

export const ZodApiResponseParser = {
  createParser: ResponseParser,
  createOptionalParser: ResponseOptionalParser,
};

export const ZodApiBodyParser = {
  createParser: BodyParser,
};

export const ZodApiParamsParser = {
  createParser: ParamsParser,
};

function ResponseOptionalParser<T extends z.ZodTypeAny>(
  schema: z.ZodOptional<T>
) {
  return (response: AxiosResponse<unknown>): AxiosResponse<z.output<T>> => {
    if (!response.data) {
      return response;
    }

    const result = schema.safeParse(response.data);

    if (result.success) {
      return { ...response, data: result.data };
    }

    logger(EnumZodApiGroup.RESPONSE_OPTIONAL, result.error, response);

    return response;
  };
}

function ResponseParser<T extends z.ZodTypeAny>(schema: T) {
  return (response: AxiosResponse<unknown>): AxiosResponse<z.output<T>> => {
    if (!('data' in response)) {
      // Quando a resposta não tem o campo data, pode significar que a função não foi chamada com o retorno de uma requisição
      throw new Error(
        'Não foi encontrado o campo data na resposta da requisição'
      );
    }

    const result = schema.safeParse(response.data);

    if (result.success) {
      return { ...response, data: result.data };
    }

    logger(EnumZodApiGroup.RESPONSE, result.error, response);

    return response;
  };
}

function ParamsParser<T extends z.ZodTypeAny>(schema: T) {
  return (params: z.input<T>): { params: z.output<T> } => {
    const result = schema.safeParse(params);

    if (result.success) {
      return { params: result.data };
    }

    logger(EnumZodApiGroup.PARAMS, result.error, params);

    return { params };
  };
}

function BodyParser<T extends z.ZodTypeAny>(schema: T) {
  return (body: z.input<T>): z.output<T> => {
    const result = schema.safeParse(body);

    if (result.success) {
      return result.data;
    }

    logger(EnumZodApiGroup.BODY, result.error, body);

    return body;
  };
}

function logger<T>(
  group: EnumZodApiGroup,
  zodContent: z.ZodError<any>,
  request: AxiosResponse<T>
) {
  const info: Record<string, any> = {
    'parser-type': GROUP_TITLE[group],
    'request-content': request,
    'zod-issues': zodContent.flatten(
      (issue) => `${issue.path.join('.')}: ${issue.message}`
    ).fieldErrors,
  };

  if (group !== EnumZodApiGroup.BODY && group !== EnumZodApiGroup.PARAMS) {
    let url = request.config.baseURL;

    if (request.config.url) {
      url += request.config.url;

      if (!isEmpty(request.config.params)) {
        url += `?${new URLSearchParams().toString()}`;
      }
    }

    info.method = request.config.method;
    info['request-content'] = request.data;
    info.url = url;
  }

  captureException(zodContent, { extra: info });

  console.groupCollapsed(
    `## %cAPI ${GROUP_TITLE[group]}:`,
    'color: mediumorchid',
    '##'
  );
  // biome-ignore lint/suspicious/noConsoleLog: Utilizado para log de validações de campos dos endpoints
  console.log(info);
  console.groupCollapsed('Stack Trace:');
  console.trace();
  console.groupEnd();
  console.groupEnd();
}

import { Box, Snackbar } from '@mui/material';
import { useEscrituracaoRetroativaV2Context } from '../escrituracaoRetroativaV2';
import type { PopoverEscrituracaoRetroativaProps } from './popoverEscrituracaoRetroativa.types';
import { PopoverEscrituracaoRetroativaBottom } from './popoverEscrituracaoRetroativaBottom';
import { PopoverEscrituracaoRetroativaCloseButton } from './popoverEscrituracaoRetroativaCloseButton';
import { PopoverEscrituracaoRetroativaContent } from './popoverEscrituracaoRetroativaContent';

export const PopoverEscrituracaoRetroativa = ({ onClose }: PopoverEscrituracaoRetroativaProps) => {
  const { modalsResultados } = useEscrituracaoRetroativaV2Context();

  return (
    <Snackbar
      open
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{ zIndex: 99 }}
      message={
        <Box display='flex' gap={1} flexDirection={'column'}>
          <Box display='flex' color='black' justifyContent={'space-between'} alignItems='center'>
            <Box display='flex' alignItems={'inherit'}>
              <PopoverEscrituracaoRetroativaContent />
              <PopoverEscrituracaoRetroativaCloseButton onClick={onClose} />
            </Box>
          </Box>
          <PopoverEscrituracaoRetroativaBottom onAbrirResumoNotasEscrituradas={modalsResultados.handleProximaEtapa} />
        </Box>
      }
      ContentProps={{
        sx: {
          transition: 'ease-in-out 0.3s all',
          backgroundColor: '#fff',
          minWidth: '0px!important',
          borderRadius: '24px!important',
        },
      }}
    />
  );
};

export enum SituacoesClientesAgronota {
  TODOS,
  ATIVOS,
  INATIVOS,
  BLOQUEADOS,
  COM_PENDENCIAS,
  CERTIFICADOS_VENCIDOS,
  CERTIFICADOS_VENCER,
  CLIENTES_SEM_CERTIFICADO,
  NOTAS_REVISAR,
  LANCAMENTOS_VENCIDOS,
  DOCUMENTOS_NAO_PROCESSADOS,
  SEM_PENDENCIAS,
}

import { axiosInstance, type DataResponse } from 'api/axiosConfig';
import type {
  CepResponse,
  CidadesResponse,
  ClassificacoesResponse,
  ListarCfopsParams,
  ListarCfopsResponse,
} from './geral.types';
import {
  type BuscarContribuinteParams,
  parseBuscarContribuinteParams,
  parseBuscarContribuinteResponse,
} from './schemas/buscarContribuinte';
import { type BuscarCnpjParamCnpj, parseBuscarCnpjResponse } from './schemas/buscarCNPJ';
import { parseBuscarCadastroContribuinteResponse } from './schemas/buscarCadastroContribuinte';
import { API_URL_AGRONOTA } from 'api/urls';
import { parseListarNCMsParams, parseListarNCMsResponse, type ListarNCMsParams } from './schemas/listarNCMs';

export const geral = {
  buscarCep: (cep: string) => axiosInstance.get<DataResponse<CepResponse>>(`/parceiro/v2/cep/${cep}`),

  listarCFOPs: (params?: ListarCfopsParams) =>
    axiosInstance.get<DataResponse<ListarCfopsResponse[]>>(`${API_URL_AGRONOTA}/parceiro/cfops`, { params }),

  buscarCNPJ: (
    cnpj: BuscarCnpjParamCnpj,
    params?: { ie?: boolean /**Força usar serviço pago para buscar ie (somente pro agronota) */ },
  ) => axiosInstance.get(`/parceiro/v2/cnpj/${cnpj}`, { params }).then(parseBuscarCnpjResponse),

  buscarCadastroContribuinte: (params?: { cpfcnpj?: string; ie?: string; uf?: string }) =>
    axiosInstance.get('/parceiro/v2/ie', { params }).then(parseBuscarCadastroContribuinteResponse),

  buscarContribuinte: (params: BuscarContribuinteParams) =>
    axiosInstance
      .get('/parceiro/cadastro-contribuinte', { ...parseBuscarContribuinteParams({ ...params }) })
      .then(parseBuscarContribuinteResponse),

  buscarCidades: (params: any) =>
    axiosInstance.get<DataResponse<CidadesResponse[]>>('/parceiro/v2/cidades', { params: params }),

  listarClassificacoes: (params: any) =>
    axiosInstance.get<DataResponse<ClassificacoesResponse[]>>('/parceiro/classificacoes', { params: params }),

  listarNCMs: (params?: ListarNCMsParams) =>
    axiosInstance
      .get(`${API_URL_AGRONOTA}/parceiro/classificacoes`, { ...parseListarNCMsParams(params) })
      .then(parseListarNCMsResponse),
};

import { type DataResponse, axiosInstance } from 'api/axiosConfig';
import type {
  BuscarRegrasAutomacaoResponse,
  BuscarResumoCertificadosResponse,
  BuscarResumoLancamentosResponse,
} from './resumo.types';

export const resumo = {
  buscarResumoLancamentos: () => axiosInstance.get<DataResponse<BuscarResumoLancamentosResponse>>('/parceiro/resumo'),

  buscarResumoCertificados: () =>
    axiosInstance.get<DataResponse<BuscarResumoCertificadosResponse>>(`/parceiro/resumo/certificados`),

  buscarResumoRegrasAutomacao: () =>
    axiosInstance.get<DataResponse<BuscarRegrasAutomacaoResponse>>(`/parceiro/resumo/regras-automacao`),

  buscarResumoLancamentosManuais: () =>
    axiosInstance.get<DataResponse<number | null>>(`/parceiro/resumo/lancamentos-manuais`),

  buscarResumoNCMs: () => axiosInstance.get<DataResponse<number>>(`/parceiro/resumo/ncm`),
};

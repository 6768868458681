import { z } from 'zod';

export type BuscarMeusDadosResponse = {
  alteracaoDoEmailDaEmpresa: {
    data_expiracao: string;
    novo_email: string;
  };
  alteracaoDoEmailDoResponsavel: {
    data_expiracao: string;
    novo_email: string;
  };
  bairro: string;
  cep: string;
  cidade: string;
  codigoCidade: number;
  complemento: string;
  cpfCnpj: string;
  dataContratacao: string;
  dataInativacao: string;
  email: string;
  emailResponsavel: string;
  exibirAbaMeuPlano: boolean;
  fone: string;
  foneResponsavel: string;
  funruralDescricao: string;
  funruralTipo: number;
  ie: string;
  logotipo: {
    conteudo: string;
    mimetype: string;
    nome: string;
    tamanho: number;
  };
  motivoInativacao: string;
  nomeFantasia: string;
  nomeResponsavel: string;
  numero: string;
  razaoSocial: string;
  regimeTributario: number;
  rntrc: string;
  rntrcDataVencimento: string;
  rua: string;
  situacao: number;
  tipoPessoa: number;
  uf: string;
};

export type EditarMeusDadosBody = {
  bairro: string;
  cep: string;
  codigoCidade?: number;
  complemento?: string;
  email?: string;
  emailResponsavel?: string;
  fone?: string;
  foneResponsavel: string;
  funruralRescricao?: string;
  funruralTipo?: number;
  ie?: string;
  logotipo?: { conteudo: string; nome: string };
  nomeFantasia: string;
  nomeResponsavel: string;
  numero: string;
  razaoSocial: string;
  regimeTributario?: number;
  rntrc?: string;
  rntrcDataVencimento?: string;
  rua: string;
};

export type RecursosResponse = {
  codigoGrupo: number;
  codigoPlano: number;
  dataContratacao: string;
  descricaoPlano: string;
  diaVencimentoBoleto: number;
  diasCarencia: number;
  nomePlano: string;
  observacaoPlano: string;
  periodicidade: number;
  qtdeBilhetagem: number;
  recursos: {
    caminhoImagem: string;
    codigoRecurso: number;
    dataContratacaoRecurso: string;
    dataExclusaoRecurso: string;
    dataSolicitacaoExcRecurso: string;
    descricaoRecurso: string;
    nomeRecurso: string;
    recursoContratado: boolean;
    slugRecurso: string;
    valorRecurso: number;
  }[];
  tipoAcesso: number;
  tipoCobranca: number;
  valor: number;
};

export enum SituacaoCobranca {
  EMITIDO = 'EMITIDO',
  LIQUIDADO = 'LIQUIDADO',
  BAIXADO = 'BAIXADO',
  ISENTADO = 'ISENTADO',
}

enum FormaPagamentoCobranca {
  BOLETO = 1,
  CARTAO_CREDITO = 3,
}

enum SituacaoNFSeCobranca {
  INDISPONIVEL,
  DISPONIVEL,
}

export type BuscarCobrancasResponse = {
  identificador: string;
  valor: number;
  dataVencimento: string;
  dataEmissao: string;
  dataPagamento: string | null;
  dataBaixa: string | null;
  descricao: string | null;
  situacao: SituacaoCobranca;
  observacaoInterna: string | null;
  formaPagamento: FormaPagamentoCobranca;
  nfseEmitida: SituacaoNFSeCobranca;
  linhaDigitavel: string;
};

export type Empresa = {
  cidade: string;
  codigo_cidade: number;
  cpf_cnpj: string;
  emp: number;
  nome: string;
  nome_fantasia: string;
  situacao: string;
  uf: string;
};

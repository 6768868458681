import { Box } from '@mui/material';
import { Button, Dialog, Grid, Typography } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';
import { formatarBoleto } from 'helpers/formatarBoleto';
import { useModalBoleto } from 'shared/modalBoleto/context/modalBoletoProvider';
import { Recibo } from 'shared/modalBoleto/modalBoleto.static';
export const SituacaoPagamentoNaoIdentificado = () => {
  const { boleto, handleAbrirBoleto, handleCopiarLinhaDigitavel, handleFalarComSuporte, handleRedirecionarMeuPlano } =
    useModalBoleto();

  return (
    <>
      <Dialog.Header>Ops, nosso robô ainda não identificou um pagamento</Dialog.Header>
      <Dialog.Content>
        <Box display='flex' justifyContent='center' alignItems='center' gap={4}>
          <img src={Recibo} />
          <Grid container spacing={2} display='flex' textAlign='center'>
            <Grid item xs={12}>
              <Typography variant='bodyLG'>Abaixo está a linha digitável do boleto em aberto</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box bgcolor={colorPalette.neutral[20]} p={1} borderRadius={2}>
                <Typography variant='titleXS'>{formatarBoleto(boleto.linhaDigitavel)}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Button variant='outlined' fullWidth onClick={handleAbrirBoleto}>
                Abrir Boleto em nova aba
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={handleCopiarLinhaDigitavel}>
                Copiar linha digitavel
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant='outlined' color='neutral' fullWidth onClick={handleRedirecionarMeuPlano}>
                Ver meus pagamentos
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog.Content>
      <Dialog.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='bodyXS'>
          Já faz 1 dia útil que você nos informou o pagamento, porém ainda não foi compensado.
        </Typography>
        <Button variant='text' onClick={handleFalarComSuporte}>
          Clique aqui para falar com o suporte
        </Button>
      </Dialog.Footer>
    </>
  );
};

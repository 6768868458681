import { axiosInstance } from 'api/axiosConfig';
import type { AxiosResponse } from 'axios';
import type {
  InserirConta,
  InserirContasEmLote,
  PlanoDeContasResponse,
  PlanoDeContaParams,
} from './planoDeContas.types';
import { API_URL_AGRONOTA } from 'api/urls';

export const planoDeContas = {
  listarPlanosDeConta: (params?: PlanoDeContaParams) =>
    axiosInstance.get<PlanoDeContasResponse>(`${API_URL_AGRONOTA}/parceiro/categorias`, { params }),

  inserirConta: (body: InserirConta) =>
    axiosInstance.post<AxiosResponse<{ codigo: number; nome: string }>>(
      `${API_URL_AGRONOTA}/parceiro/categorias`,
      body,
    ),

  inserirContasEmLote: (body: InserirContasEmLote) =>
    axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/categorias/inserir-lote`, body),

  editarConta: (codigo: number, body: Omit<InserirConta, 'tipo'>) =>
    axiosInstance.put(`${API_URL_AGRONOTA}/parceiro/categorias/${codigo}`, body),

  verificarLancamentosAbertos: (codigo: number) =>
    axiosInstance.get<
      AxiosResponse<{
        possui_lancamentos: boolean;
      }>
    >(`${API_URL_AGRONOTA}/parceiro/categorias/${codigo}/verificar-se-possui-lancamentos-abertos`),

  verificarLancamentosDosClientes: () =>
    axiosInstance.get<AxiosResponse<{ possui: boolean }>>(
      `${API_URL_AGRONOTA}/parceiro/categorias/verificar-categorias-clientes`,
    ),

  importarContasDominio: (body: { base64: string }) =>
    axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/categorias/importar-dominio`, body),

  excluirConta: (codigo: number) => axiosInstance.delete(`${API_URL_AGRONOTA}/parceiro/categorias/${codigo}`),

  excluirTransferirConta: (codigo: number, codigoDestino: number) =>
    axiosInstance.delete(`${API_URL_AGRONOTA}/parceiro/categorias/${codigo}/${codigoDestino}`),

  excluirContasClientesVinculados: () =>
    axiosInstance.delete(`${API_URL_AGRONOTA}/parceiro/categorias/categorias-clientes`),
};

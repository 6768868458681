import { PlanoDeContaParams } from 'api/planoDeContas/planoDeContas.types';

export type PlanoDeConta = {
  codigo: number;
  nome: string;
  nivel: number;
  tipo: TipoPlanoConta;
  path: string[];
  categorias_filho: Map<number, PlanoDeConta> | null;
  ir?: number;
  atividadeRural?: number;

  nomeCategoriaPai?: string;
  codigo_categoria_pai: number | null;
  irPai?: number;
  atividadeRuralPai?: number;
};

export enum TipoPlanoConta {
  RECEITA = 1,
  DESPESA = 2,
}

export enum FinalidadePlanoConta {
  // Tem subcontas
  SINTETICA = 1,
  // Não tem subcontas
  ANALITICA = 2,
}

export type MapPlanoDeContas = Map<number, PlanoDeConta>;

export type PlanoDeContasState = {
  possuiClientesComLancamentos: boolean;
  planoDeContas: MapPlanoDeContas;
  planoDeContasLinear: PlanoDeConta[] | null;
  loading: boolean;
  search: string;
};

export type PlanoDeContasStore = PlanoDeContasState & {
  verificarSeTemClientesComLancamentos: () => void;
  listarPlanoDeContas: (params?: PlanoDeContaParams) => Promise<void>;
  setSearch: (term?: string) => void;
  limparStore(): void;
};

import styled from '@emotion/styled';
import { colorPalette } from '@s_mart/tokens';
import { toRem } from '@s_mart/utils';

export const StyledNovidadesPopper = styled.div`
  width: ${toRem(452)};
  max-height: 85dvh;
  padding: 16px;
  background-color: ${colorPalette.neutral[20]};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`;

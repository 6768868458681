import styled from '@emotion/styled';

export const ImagePreviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  line-height: 0;

  &:hover > button {
    opacity: 1;
    cursor: pointer;
  }

  & > img {
    cursor: pointer;
  }
`;

export const ImagePreviewButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.55);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border: none;
  padding: 0;
`;

export const ImageOverlayToolbar = styled.div`
  margin: 16px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  z-index: 1;
`;

export const ImgOverlayContainer = styled.div`
  transition: transform 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    max-width: 80vw;
    max-height: 80vh;
  }
`;

import * as React from 'react';

import { Arrow, PopoverInterno } from './helpMenu.styles';
import { Popover } from '@l_ine/core';

interface IMenu extends React.PropsWithChildren {
  menuOpen: (EventTarget & HTMLDivElement) | null;
  setMenuOpen: (menuOpen: (EventTarget & HTMLDivElement) | null) => void;
}

export const Menu = ({ menuOpen, setMenuOpen, children }: IMenu) => {
  if (!menuOpen) {
    return null;
  }

  return (
    <Popover open={Boolean(menuOpen)} onClose={() => setMenuOpen(null)} anchorEl={menuOpen} placement='bottom-end'>
      <PopoverInterno>
        <div>{children}</div>
        <Arrow posicao='end' />
      </PopoverInterno>
    </Popover>
  );
};

export default React.memo(Menu);

import { ZodApiResponseParser } from 'api/zodApi';
import z from 'zod';

const _schemaItemCadastroContribuinteBody = z
  .object({
    cnae: z.string(),
    cpf_cnpj: z.string(),
    credenciado_a_emitir_cte: z.number(),
    credenciado_a_emitir_nfe: z.number(),
    data_baixa: z.string(),
    data_inicio_atividades: z.string(),
    data_ultima_atualizacao: z.string().nullable(),
    endereco: z
      .object({
        bairro: z.string(),
        cep: z.string(),
        complemento: z.string(),
        logradouro: z.string(),
        municipio: z.string(),
        municipio_codigo: z.number(),
        numero: z.string(),
        uf: z.string(),
      })
      .deepPartial()
      .optional()
      .nullable(),
    ie: z.string(),
    nome: z.string(),
    regime_de_apuracao: z.string(),
    situacao: z.number(),
    uf: z.string(),
  })
  .deepPartial();

const _schemaCadastroContribuinteBody = z.object({
  data: z.array(_schemaItemCadastroContribuinteBody).nullable(),
});

export const parseBuscarCadastroContribuinteResponse = ZodApiResponseParser.createParser(
  _schemaCadastroContribuinteBody,
);

export type BuscarCadastroContribuinteResponse = z.infer<typeof _schemaCadastroContribuinteBody>;
export type ItemBuscarCadastroContribuinteResponse = z.infer<typeof _schemaItemCadastroContribuinteBody>;

import { GridColDef } from '@mui/x-data-grid';
import { StateEtapaModalResumoDocumentosValue } from 'contexts/escrituracaoRetroativaV2/useModalsEscrituracaoRetroativaState.types';

const colunas: Array<GridColDef & { field: keyof StateEtapaModalResumoDocumentosValue }> = [
  {
    field: 'produtor',
    headerName: 'Produtor principal',
    flex: 1,
  },
  {
    field: 'quantidadeNotasEscrituradasOuIgnoradas',
    headerName: 'Escrituradas ou Ignoradas',
    flex: 0.6,
  },
  {
    field: 'quantidadeNotasNaoEscrituradas',
    headerName: 'Não escrituradas',
    flex: 0.5,
  },
];

export const useEtapaResumoDocumentosNaoEscrituradosPorProdutor = () => {
  return {
    colunas,
  };
};

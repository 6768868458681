import { IntegradoComDominioDialog } from 'shared/integracaoDominio/integradoComDominioDialog/integradoComDominioDialog';
import { useIntegradoComDominioDialogStore } from 'shared/integracaoDominio/integradoComDominioDialog/useIntegradoComDominioDialog.store';
import { IntegrarComDominioDialog } from 'shared/integracaoDominio/integrarComDominioDialog/integrarComDominioDialog';
import { useIntegrarComDominioDialogStore } from 'shared/integracaoDominio/integrarComDominioDialog/integrarComDominioDialog.store';

export function Dialogs() {
  const _integrarComDomonioDialog = useIntegrarComDominioDialogStore();
  const _integradoComDominioDialog = useIntegradoComDominioDialogStore();

  return (
    <>
      {_integrarComDomonioDialog.open && <IntegrarComDominioDialog />}
      {_integradoComDominioDialog.open && <IntegradoComDominioDialog />}
    </>
  );
}

import { Dialog } from '@s_mart/core';
import { SituacaoBoletoDisponivel } from './situacoes/situacaoBoletoDisponivel/situacaoBoletoDisponivel';
import { SituacaoPagamentoNaoIdentificado } from './situacoes/situacaoPagamentoNaoIdentificado/situacaoPagamentoNaoIdentificado';
import { SituacaoUnicoBoletoVencido } from './situacoes/situacaoUnicoBoletoVencido/situacaoUnicoBoletoVencido';
import { SituacaoVariosBoletosVencidos } from './situacoes/situacaoVariosBoletosVencidos/situacaoVariosBoletosVencidos';
import useAppStore from 'store/app/app';
import { ConfirmacaoMarcarComoPago } from './situacoes/confirmacaoMarcarComoPago/confirmacaoMarcarComoPago';
import { ModalBoletoSituacao } from 'store/app/app.types';
import { useModalBoleto } from './context/modalBoletoProvider';

const ModalBoleto = () => {
  const { handleClose } = useModalBoleto();
  const modalBoleto = useAppStore(state => state.modalBoleto);

  if (modalBoleto === null) {
    return <></>;
  }

  return (
    <Dialog
      open
      maxWidth={modalBoleto === ModalBoletoSituacao.UNICO_BOLETO_VENCIDO ? 'lg' : 'md'}
      onClose={handleClose}
      closeIcon
      disableBackdropClick>
      {modalBoleto === ModalBoletoSituacao.BOLETO_DISPONIVEL && <SituacaoBoletoDisponivel />}
      {modalBoleto === ModalBoletoSituacao.PAGAMENTO_NAO_IDENTIFICADO && <SituacaoPagamentoNaoIdentificado />}
      {modalBoleto === ModalBoletoSituacao.UNICO_BOLETO_VENCIDO && <SituacaoUnicoBoletoVencido />}
      {modalBoleto === ModalBoletoSituacao.VARIOS_BOLETOS_VENCIDOS && <SituacaoVariosBoletosVencidos />}
      {modalBoleto === ModalBoletoSituacao.CONFIRMACAO_DESBLOQUEIO && <ConfirmacaoMarcarComoPago />}
    </Dialog>
  );
};

export default ModalBoleto;

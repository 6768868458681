import { PlanoDeConta } from './planoDeContasStore.types';

export function parsePlanoContasMap(planosConta: PlanoDeConta[]): Map<number, PlanoDeConta> {
  const map = new Map<number, PlanoDeConta>();
  const helperMap = new Map<number, PlanoDeConta>();

  for (const plano of planosConta) {
    if (plano.codigo_categoria_pai !== null) {
      const parent = helperMap.get(plano.codigo_categoria_pai);

      if (parent) {
        plano.path = [...parent.path.slice(0, -1), ...plano.path];
        if (parent.categorias_filho !== null) {
          parent.categorias_filho.set(plano.codigo, plano);
        } else {
          parent.categorias_filho = new Map<number, PlanoDeConta>([[plano.codigo, plano]]);
        }
      }
    } else {
      map.set(plano.codigo, plano);
    }
    helperMap.set(plano.codigo, plano);
  }

  return map;
}

import styled from '@emotion/styled';
import { Grid } from '@s_mart/core';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  input:focus {
    .results {
      display: flex;
    }
  }
`;

export const SearchResults = styled.div`
  max-height: 225px;
  overflow-y: auto;
  width: 100%;
  max-width: 354px;
  min-width: 308px;
  margin-top: 8px;
  background: #ffffff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
`;

export const SearchSection = styled.div`
  display: flex;
  flex-direction: column;

  .line-typography-bodyXXS {
    display: flex;
    text-transform: uppercase;
    color: #8b8b8b;
    margin-bottom: 2px;
  }

  a {
    display: block;
    color: #000;
    text-decoration: none;

    & + a {
      margin-top: 8px;
    }
  }

  & + & {
    margin-top: 12px;
  }
`;

import { throttle } from 'lodash-es';
import React from 'react';

import { api } from 'api/api';

const useCities = (): any => {
  const [cities, setCities] = React.useState<any[]>([]);
  const [citiesLoading, setCitiesLoading] = React.useState(false);

  const handleSearchCities = throttle(
    async (term) => {
      if (!term) return;
      try {
        setCitiesLoading(true);
        const { data: res } = await api.geral.buscarCidades({ search: term });
        if (res.data) {
          setCities(
            res.data.map((item: any) => ({
              key: item.codigo,
              value: item.codigo,
              label: `${item.nome} - ${item.uf}`,
              uf: item.uf,
              cidade: item.nome,
            })),
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setCitiesLoading(false);
      }
    },
    500,
    { leading: false },
  );

  return { cities, handleSearchCities, citiesLoading };
};

export default useCities;

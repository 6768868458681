import { useState, type DetailedHTMLProps, type ImgHTMLAttributes } from 'react';
import { ImagePreviewButton, ImagePreviewContainer } from './imagePreview.styles';
import { ImageOverlay } from './imageOverlay';
import { Portal } from '@mui/material';
import { LIcon } from '@s_mart/core';
import { lineEye } from '@s_mart/solid-icons';

type ImagePreviewProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const ImagePeview = (props: ImagePreviewProps) => {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <>
      {showPreview && (
        <Portal>
          <ImageOverlay imgProps={props} onClose={() => setShowPreview(false)} />
        </Portal>
      )}
      <ImagePreviewContainer>
        <ImagePreviewButton onClick={() => setShowPreview(true)}>
          <LIcon icon={lineEye} color='white' size='2rem' />
        </ImagePreviewButton>
        <img {...props} width={props?.width ?? '100%'} />
      </ImagePreviewContainer>
    </>
  );
};

import { SelecionarEmpresa as SmartSelecionarEmpresa } from '@s_mart/selecionar-empresa';
import { useModalMultiEmpresa } from './useModalMultiEmpresa';
import useAuthStore from 'store/auth/auth';

export const ModalMultiEmpresa = ({ onClose }: { onClose(): void }) => {
  const [trocarEmpresaAtual, authLoading, empresaAtual] = useAuthStore((s) => [
    s.trocarEmpresaAtual,
    s.authLoading,
    s.empresaAtual,
  ]);

  const { empresas, isLoading } = useModalMultiEmpresa();

  return (
    <SmartSelecionarEmpresa
      dialogTitle='Selecionar empresa'
      data={empresas.map((company) => ({
        nome: company.nome_fantasia,
        emp: company.emp,
        cpf_cnpj: String(company.cpf_cnpj),
        cidade: company.cidade,
        codigo_cidade: company.codigo_cidade,
        situacao: company.situacao,
        uf: company.uf,
      }))}
      onClose={onClose}
      onSelecionarEmpresa={(empresa) => trocarEmpresaAtual(empresa.emp)}
      isLoading={authLoading || isLoading}
      closeIcon={empresaAtual !== null}
      codigoEmpresaAtual={empresaAtual ?? undefined}
    />
  );
};

import styled from '@emotion/styled';
import { colorPalette } from '@s_mart/tokens';
import { toRem } from '@s_mart/utils';

export const CardInfo = styled.div`
  border: 1px solid ${colorPalette.neutral[100]};
  border-radius: 16px;

  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${toRem(16)};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(4)};
`;

import { axiosInstance } from 'api/axiosConfig';
import type { TipoProdutor } from 'containers/simuladorIR/modalCadastroEdicaoProdutor/modalCadastroEdicaoProdutor.types';
import type { EnviarPorEmailSimulacaoBody, InserirSimulacaoIRBody } from './simuladorIR.types';

export const simuladorIR = {
  buscarUltimaSimulacao: (codigoProdutor: number, tipo: TipoProdutor) =>
    axiosInstance.get('/parceiro/simulacao-ir', {
      params: { produtor: codigoProdutor, tipo: tipo },
    }),

  inserirSimulacao: (body: InserirSimulacaoIRBody) => axiosInstance.post('/parceiro/simulacao-ir', body),

  enviarPorEmailSimulacaoIR: (body: EnviarPorEmailSimulacaoBody) =>
    axiosInstance.post(`/parceiro/simulacao-ir/enviar-email`, body),
};

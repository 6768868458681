import { create } from 'zustand';

import type { UsuariosState, UsuariosStore } from './usuarios.types';

import useAppStore from 'store/app/app';
import { api } from 'api/api';

const _initialState: UsuariosState = {
  usuarios: [],
  convites: [],
  loading: true,
};

export const useUsuariosStore = create<UsuariosStore>((set, get) => ({
  ..._initialState,
  buscarUsuarios: async () => {
    try {
      const { data: usuarios } = await api.usuarios.listar();
      const { data: convites } = await api.convites.listar();

      set({
        usuarios: usuarios.data,
        convites: convites.data,
        loading: false,
      });
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  convidarUsuario: async (payload, callback) => {
    try {
      useAppStore.getState().setLoading(true);

      await api.convites.enviar(payload);

      useAppStore.getState().setNotification({
        title: 'Convite enviado',
        message: 'Enviamos um convite para o e-mail inserido',
      });

      get().buscarUsuarios();

      callback?.();

      useAppStore.getState().setLoading(false);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  removerConviteUsuario: async id => {
    try {
      useAppStore.getState().setLoading(true);

      await api.convites.excluir(Number(id));

      useAppStore.getState().setNotification({
        title: 'Convite removido',
      });

      set(state => ({
        convites: state.convites.filter(convite => convite.codigo !== id),
      }));

      useAppStore.getState().setLoading(false);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  atualizarUsuario: async (payload, callback) => {
    try {
      await api.usuarios.editar(payload);

      useAppStore.getState().setNotification({
        title: 'Usuário atualizado',
      });

      get().buscarUsuarios();

      set(state => ({
        usuarios: state.usuarios.map(usuario => (usuario.codigo === payload.codigo ? payload : usuario)),
      }));

      callback?.();
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  removerUsuario: async (id, callback) => {
    try {
      await api.usuarios.excluir(id);

      useAppStore.getState().setNotification({
        title: 'Usuário removido',
      });

      get().buscarUsuarios();

      callback?.();
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  limparStore() {
    set(_initialState);
  },
}));

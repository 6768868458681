import { api } from 'api/api';
import { useState } from 'react';
import type { CepLocalidadeTypes } from './useCep.types';

const useCep = () => {
  const [cepLoading, setCepLoading] = useState(false);
  const [localidade, setLocalidade] = useState<CepLocalidadeTypes>();

  const handleBuscarCep = async (cep: string) => {
    try {
      setCepLoading(true);
      const { data } = await api.geral.buscarCep(cep);
      if (data?.data) {
        setLocalidade(data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCepLoading(false);
    }
  };
  return { cepLoading, localidade, handleBuscarCep };
};

export default useCep;

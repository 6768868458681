import styled from '@emotion/styled';

export const BannerContainer = styled.div`
  background-color: #2e2e2e;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const Banner = styled.img`
  position: absolute;
  overflow: hidden;
  height: 55vh;
  bottom: 0;
  right: 0;
  z-index: 100;
`;

export const BannerText = styled.h3`
  width: 290px;
  color: white;
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;
  margin-top: 16px;

  > span {
    color: white;
    padding: 4px;
    border-radius: 4px;
    margin-left: -4px;
    font-weight: bold;
  }
`;

export const HalfCircle = styled.img`
  position: absolute;
  overflow: hidden;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: -3px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: auto;
`;

export const FooterWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 50px;
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  background-color: ${props => props.theme.palette.primary.main};
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
`;

import styled from '@emotion/styled';
import { colorPalette } from '@s_mart/tokens';

export const Container = styled.button`
  padding: 0 !important;
  outline: none !important;
  text-align: start;
  border: 1px solid ${colorPalette.neutral[50]};
  border-radius: 6px;
  background-color: ${colorPalette.neutral[10]};
  display: flex;
  align-items: center;
  height: 6rem;
  width: 100% !important;
  &:hover {
    background-color: ${colorPalette.neutral[30]};
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 8px 16px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 8px;
`;

export const Links = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 8px;
  font-size: 13px;

  a {
    text-decoration: none;
    color: #333;
    margin-right: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

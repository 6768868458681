import { Button, Grid, LIcon, Typography } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';
import { cnpj } from '@s_mart/masks';
import { lineBriefcase, lineBuildingUser } from '@s_mart/solid-icons';
import { lineExchange } from '@s_mart/light-icons';

import { useIntegrarComDominioDialogStore } from '../integrarComDominioDialog.store';
import { CardInfo, IconContainer, InfoContainer } from './conteudoSegundoPasso.styles';

export function ConteudoSegundoPasso() {
  const { handleVoltarPasso, dadosIntegracao } = useIntegrarComDominioDialogStore();

  return (
    <Grid container justifyContent='center' gap={2}>
      <Grid item xs={12}>
        <CardInfo>
          <IconContainer>
            <LIcon icon={lineBuildingUser} size='48px' removeMargin />
          </IconContainer>
          <InfoContainer>
            <Typography variant='bodySM'>Empresa:</Typography>
            <Typography variant='titleSM'>{dadosIntegracao?.clienteNome}</Typography>
            <Typography variant='bodySM'>CNPJ: {cnpj.format(dadosIntegracao?.clienteCnpj || '')}</Typography>
          </InfoContainer>
        </CardInfo>
      </Grid>
      <Grid item>
        <LIcon icon={lineExchange} removeMargin size='48px' style={{ rotate: '90deg' }} />
      </Grid>
      <Grid item xs={12}>
        <CardInfo>
          <IconContainer>
            <LIcon icon={lineBriefcase} size='48px' removeMargin />
          </IconContainer>
          <InfoContainer>
            <Typography variant='bodySM'>Escritório contábil:</Typography>
            <Typography variant='titleSM'>{dadosIntegracao?.parceiroNome}</Typography>
            <Typography variant='bodySM'>CNPJ: {cnpj.format(dadosIntegracao?.parceiroCnpj || '')}</Typography>
          </InfoContainer>
        </CardInfo>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Grid container alignItems='center' direction='column' spacing={1}>
          <Grid item>
            <Button type='submit'>Confirmar integração</Button>
          </Grid>
          <Grid item>
            <Button
              variant='text'
              style={{ color: colorPalette.neutral[100], fontWeight: 400 }}
              onClick={() => handleVoltarPasso()}>
              Não integrar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { Button, Dialog, Typography } from '@s_mart/core';
import type { ConfirmationDialogProps } from './confirmation.types';
import { conditionalReverse } from 'utils/conditionalReverse';

export const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  primaryActionText,
  secondaryActionText,
  DialogProps,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      closeIcon
      maxWidth={DialogProps?.maxWidth ?? 'xs'}
      onClose={onClose}
      disableBackdropClick
      transitionDuration={0}
      {...(DialogProps ?? {})}
    >
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Content style={{ padding: '16px' }}>
        <>
          {typeof description === 'string' ? (
            <Typography variant='bodySM'>
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </Typography>
          ) : (
            description
          )}
        </>
      </Dialog.Content>
      <Dialog.Footer>
        {conditionalReverse(
          [
            <Button key='first' variant='neutral' onClick={onClose}>
              {secondaryActionText || 'Cancelar'}
            </Button>,
            <Button key='second' color={variant === 'danger' ? 'error' : 'primary'} onClick={onSubmit}>
              {primaryActionText || 'Confirmar'}
            </Button>,
          ],
          variant === 'danger',
        )}
      </Dialog.Footer>
    </Dialog>
  );
};

import { useQuery } from '@tanstack/react-query';
import { api } from 'api/api';
import { empresa } from 'api/empresa/empresa';
import React, { useMemo } from 'react';

export const useModalMultiEmpresa = () => {
  const empresasQuery = useQuery({
    queryKey: ['listar-empresas-query'],
    queryFn: () => api.empresa.listarEmpresasUsuario(),
  });

  const empresas = useMemo(
    () => (empresasQuery.data?.data.data ? Object.values(empresasQuery.data.data.data) : []),
    [empresasQuery.data],
  );
  return { empresas, isLoading: empresasQuery.isFetching };
};

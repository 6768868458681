import { VariantType } from 'notistack';

export enum ResponseType {
  AUTH_ERROR = -1,
  ERROR = 0,
  WARNING = 1,
  INFO = 2,
  SUCCESS = 3,
}

export const getResponseTypeLabel = (type: ResponseType): VariantType => {
  switch (type) {
    case ResponseType.ERROR:
      return 'error';
    case ResponseType.AUTH_ERROR:
      return 'warning';
    case ResponseType.WARNING:
      return 'warning';
    case ResponseType.INFO:
      return 'info';
    case ResponseType.SUCCESS:
      return 'success';
    default:
      return 'default';
  }
};

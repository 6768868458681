import { ContentObjectNumber } from 'containers/simuladorIR/simuladorIR.types';

export interface ClientFormDialogProps {
  onClose(): void;
  callback: any;
}

export interface NovoClienteFormTypes {
  cpfCnpj: string;
  regimeTributario: ContentObjectNumber;
  ie?: string;
  ieIsento?: boolean;
  nomeFantasia?: string;
  nome: string;
  email: string;
  telefone?: string;
  rntrc?: string;
  nomeResponsavel?: string;
  telefoneResponsavel?: string;
  emailResponsavel?: string;
  cidade: ContentObjectNumber & {
    uf: string;
  };
  cep: string;
  numero: string;
  rua: string;
  complemento?: string;
  bairro: string;
  clienteComoResponsavel: boolean;
}

export enum TipoPessoa {
  FISICA,
  JURIDICA,
}

import { css, Global } from '@emotion/react';

import { Global as GlobalSmart } from '@s_mart/styles';
import { colorPalette } from '@s_mart/tokens';

export function GlobalStyles() {
  return (
    <>
      <GlobalSmart />
      <Global
        styles={css`
          html,
          body {
            background-color: ${colorPalette.neutral[20]};
          }
        `}
      />
    </>
  );
}

import { Stack } from '@mui/material';
import { Button, Dialog, Typography } from '@s_mart/core';
import { useModalBoleto } from 'shared/modalBoleto/context/modalBoletoProvider';
import { MulherOk } from 'shared/modalBoleto/modalBoleto.static';

export const ConfirmacaoMarcarComoPago = () => {
  const { handleClose } = useModalBoleto();
  return (
    <>
      <Dialog.Header>Pendencia informada como pago!</Dialog.Header>
      <Dialog.Content>
        <Stack direction='row' alignItems='center'>
          <img src={MulherOk} width={150} />

          <Typography variant='bodyMD'>
            Obrigado por nos avisar que você efetuou o pagamento!
            <br />
            <br />
            Seu sistema será liberado por 1 dia útil enquanto o seu pagamento compensa.
          </Typography>
        </Stack>
      </Dialog.Content>
      <Dialog.Footer>
        <Button onClick={handleClose} fullWidth>
          Entendi
        </Button>
      </Dialog.Footer>
    </>
  );
};

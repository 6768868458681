import { ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

const schemaItemBuscarProdutoResponse = z.object({
  codigo: z.number(),
  codigoBarra: z.string().nullable(),
  funrural: z.number(),
  funruralPj: z.number(),
  ncm: z.string(),
  ncmNome: z.string(),
  nome: z.string(),
  pertence_parceiro: z.boolean(),
  unidade: z.string().nullable(),
});

const schemaBuscarProdutoResponse = z.object({
  data: schemaItemBuscarProdutoResponse,
});

export const parseBuscarProdutoResponse = ZodApiResponseParser.createParser(schemaBuscarProdutoResponse);

export type BuscarProdutoResponse = z.output<typeof schemaBuscarProdutoResponse>;
export type ItemBuscarProdutoResponse = z.infer<typeof schemaItemBuscarProdutoResponse>;

import { Button, Dialog, Grid, LIcon, Typography } from '@s_mart/core';
import { lineExchange } from '@s_mart/light-icons';
import { cnpj } from '@s_mart/masks';
import { lineBriefcase, lineBuildingUser } from '@s_mart/solid-icons';
import { RemoverIntegracaoDialog } from '../removerIntegracaoDialog/removerIntegracaoDialog';

import { CardInfo, IconContainer, InfoContainer } from './integradoComDominioDialog.styles';
import { useIntegradoComDominioDialog } from './useIntegradoComDominioDialog';
import { useIntegradoComDominioDialogStore } from './useIntegradoComDominioDialog.store';

export function IntegradoComDominioDialog() {
  const { open, dadosIntegracao, handleClose } = useIntegradoComDominioDialogStore();

  const { removerIntegracaoDialog, handleCloseRemoverIntegracaoDialog, handleOpenRemoverIntegracaoDialog } =
    useIntegradoComDominioDialog();

  return (
    <>
      <Dialog open={open} onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
        <Dialog.Header>
          <Typography variant='titleMD'>Seu sistema está integrado</Typography>
        </Dialog.Header>
        <Dialog.Content>
          <Grid container justifyContent='center' gap={2}>
            <Grid item xs={12}>
              <CardInfo>
                <IconContainer>
                  <LIcon icon={lineBuildingUser} size='48px' removeMargin />
                </IconContainer>
                <InfoContainer>
                  <Typography variant='bodySM'>Empresa:</Typography>
                  <Typography variant='titleSM'>{dadosIntegracao?.clienteNome}</Typography>
                  <Typography variant='bodySM'>CNPJ: {cnpj.format(dadosIntegracao?.clienteCnpj || '')}</Typography>
                </InfoContainer>
              </CardInfo>
            </Grid>
            <Grid item>
              <LIcon icon={lineExchange} removeMargin size='48px' style={{ rotate: '90deg' }} />
            </Grid>
            <Grid item xs={12}>
              <CardInfo>
                <IconContainer>
                  <LIcon icon={lineBriefcase} size='48px' removeMargin />
                </IconContainer>
                <InfoContainer>
                  <Typography variant='bodySM'>Escritório contábil:</Typography>
                  <Typography variant='titleSM'>{dadosIntegracao?.parceiroNome}</Typography>
                  <Typography variant='bodySM'>CNPJ: {cnpj.format(dadosIntegracao?.parceiroCnpj || '')}</Typography>
                </InfoContainer>
              </CardInfo>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                color='error'
                style={{ fontWeight: 400 }}
                onClick={handleOpenRemoverIntegracaoDialog}>
                Remover integração
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
      {removerIntegracaoDialog && (
        <RemoverIntegracaoDialog open={removerIntegracaoDialog} handleClose={handleCloseRemoverIntegracaoDialog} />
      )}
    </>
  );
}

import { axiosInstance } from 'api/axiosConfig';

export const convites = {
  listar: () => axiosInstance.get('/parceiro/invites'),

  enviar: (body: { nome: string; email: string }) =>
    axiosInstance.post('/parceiro/invites', body),

  excluir: (codigo: number) =>
    axiosInstance.delete(`/parceiro/invites/${codigo}`),
};

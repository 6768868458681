import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: 32px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  gap: 8px;

  img {
    margin-bottom: 32px;
  }

  .line-typography-titleMD {
    margin-bottom: 8px;
  }

  .line-button-contained {
    margin-top: 16px;
  }
`;

import { Config } from 'config';
import useUsuarioSBSistemas from 'hooks/useUsuarioSBSistemas';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import useAuthStore from 'store/auth/auth';
import { isStaging } from 'utils/isStaging';

export function usePostHogInit() {
  const posthog = usePostHog();

  const { isUsuarioSBSistemas } = useUsuarioSBSistemas();
  useEffect(() => {
    if (!isStaging() && Config.POSTHOG_API_KEY) {
      posthog?.init(Config.POSTHOG_API_KEY, {
        api_host: Config.POSTHOG_HOST,
        disable_session_recording: isUsuarioSBSistemas,
        disable_persistence: isUsuarioSBSistemas,
        person_profiles: 'identified_only',
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
          },
        },
      });
    }
  }, [isUsuarioSBSistemas]);

  const tokenDescodificado = useAuthStore((state) => state.perfil);
  useEffect(() => {
    if (tokenDescodificado) {
      posthog?.identify(tokenDescodificado.emp?.toString(), tokenDescodificado);
    }
  }, [posthog, tokenDescodificado]);

  return posthog;
}

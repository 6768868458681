import { EnumSistemas } from 'constants/sistemas';

export enum ROTAS_PRIVADAS {
  RESUMO = '/resumo',
  CLIENTES = '/clientes',
  GRUPOS_PRODUTORES = '/grupos-produtores',
  CONFIGURACOES = '/config',
  CONFIGURACOES_MINHA_CONTA = '/config/minha-conta',
  CONFIGURACOES_USUARIOS = '/config/usuarios',
  CONFIGURACOES_LANCAMENTOS = '/config/lancamento',
  PLANO_DE_CONTAS = '/plano-de-contas',
  PRODUTOS = '/produtos',
  SIMULADOR_IR = '/simulador-ir',
  AUTOMACOES = '/automacoes',
}

export const PERMISSOES_ROTAS_PRIVADAS_POR_SISTEMA: Record<ROTAS_PRIVADAS, Array<EnumSistemas>> = {
  [ROTAS_PRIVADAS.RESUMO]: [EnumSistemas.AGRONOTA],
  [ROTAS_PRIVADAS.CLIENTES]: [EnumSistemas.SIMPLESCTE],
  [ROTAS_PRIVADAS.GRUPOS_PRODUTORES]: [EnumSistemas.AGRONOTA],
  [ROTAS_PRIVADAS.CONFIGURACOES]: [EnumSistemas.AGRONOTA, EnumSistemas.SIMPLESCTE],
  [ROTAS_PRIVADAS.CONFIGURACOES_MINHA_CONTA]: [EnumSistemas.AGRONOTA, EnumSistemas.SIMPLESCTE],
  [ROTAS_PRIVADAS.CONFIGURACOES_USUARIOS]: [EnumSistemas.AGRONOTA, EnumSistemas.SIMPLESCTE],
  [ROTAS_PRIVADAS.CONFIGURACOES_LANCAMENTOS]: [EnumSistemas.AGRONOTA],
  [ROTAS_PRIVADAS.PLANO_DE_CONTAS]: [EnumSistemas.AGRONOTA],
  [ROTAS_PRIVADAS.PRODUTOS]: [EnumSistemas.AGRONOTA],
  [ROTAS_PRIVADAS.SIMULADOR_IR]: [EnumSistemas.AGRONOTA],
  [ROTAS_PRIVADAS.AUTOMACOES]: [EnumSistemas.AGRONOTA],
};

import { PlanoDeConta } from './planoDeContasStore.types';

export const planoContaReceitaPai: PlanoDeConta = {
  codigo: -1,
  nome: 'Receitas',
  nivel: 0,
  codigo_categoria_pai: null,
  tipo: 1,
  categorias_filho: null,
  path: ['-1'],
};

export const planoContaDespesaPai: PlanoDeConta = {
  codigo: -2,
  nome: 'Despesas',
  nivel: 0,
  codigo_categoria_pai: null,
  tipo: 2,
  categorias_filho: null,
  path: ['-2'],
};

import { create } from 'zustand';
import { CobrancasState, CobrancasStore } from './cobrancasStore.types';
import { api } from 'api/api';
import useAppStore from 'store/app/app';
import { BuscarCobrancasResponse, SituacaoCobranca } from 'api/empresa/empresa.types';
import dayjs from 'dayjs';

const initalState: CobrancasState = {
  cobrancas: [],
  boletosEmAberto: [],
  boletosVencidos: [],
};

export const useCobrancasStore = create<CobrancasStore>(set => ({
  ...initalState,
  async listarCobrancas() {
    try {
      const boletosVencidos: BuscarCobrancasResponse[] = [];
      const boletosEmAberto: BuscarCobrancasResponse[] = [];
      const { data: response } = await api.empresa.buscarCobrancas();

      for (const boleto of response.data) {
        if (boleto.situacao === SituacaoCobranca.EMITIDO) {
          if (dayjs(boleto.dataVencimento).isBefore(dayjs().add(-1, 'day'))) {
            boletosVencidos.push(boleto);
            continue;
          }
          boletosEmAberto.push(boleto);
        }
      }

      set({
        cobrancas: response.data,
        boletosEmAberto,
        boletosVencidos,
      });
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
}));

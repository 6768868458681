import { listQueryParamsSchema } from 'api/axiosConfig';
import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

const schemaItemListarCAProdutosResponse = z.object({
  codigo: z.number(),
  codigoGrupo: z.number().nullable(),
  funruralPf: z.number(),
  funruralPj: z.number(),
  ncm: z.string(),
  ncmNome: z.string(),
  nome: z.string(),
  nomeGrupo: z.string().nullable(),
  unidadeComercial: z.string(),
  unidadeTributavel: z.string(),
});

const schemaListarCAProdutosResponse = z.object({
  data: z.array(schemaItemListarCAProdutosResponse),
});

const schemaListarCAProdutosParams = z
  .object({
    grupos: z.array(z.number().or(z.string())).optional(),
  })
  .merge(listQueryParamsSchema)
  .optional();

export const parseListarCAProdutosResponse = ZodApiResponseParser.createParser(schemaListarCAProdutosResponse);
export const parseListarCAProdutosParams = ZodApiParamsParser.createParser(schemaListarCAProdutosParams);

export type ListarCAProdutosParams = z.infer<typeof schemaListarCAProdutosParams>;
export type ListarCAProdutosResponse = z.infer<typeof schemaListarCAProdutosResponse>;
export type ItemListarCAProdutosResponse = z.infer<typeof schemaItemListarCAProdutosResponse>;

import { z } from 'zod';
import { ZodApiResponseParser } from 'api/zodApi';
import { EnumAutomacaoAgronota } from 'constants/configuracoes';

const _schemaItemBuscarConfiguracoesResponse = z.object({
  onboarding: z.number(),
  temSimulacao: z.boolean(),
  usuariosApresentacaoDominio: z.number().array().nullable(),
  usuariosApresentacaoSimuladoIR: z.number().array().nullable(),
  apresentacoes: z.string().nullable(),
  automacaoAgronota: z.nativeEnum(EnumAutomacaoAgronota),
});

const _schemaBuscarConfiguracoesResponse = z.object({
  data: _schemaItemBuscarConfiguracoesResponse,
});

export const parseBuscarConfiguracoesResponse = ZodApiResponseParser.createParser(_schemaBuscarConfiguracoesResponse);

export type BuscarConfiguracoesResponse = z.infer<typeof _schemaBuscarConfiguracoesResponse>;
export type ItemBuscarConfiguracoesResponse = z.infer<typeof _schemaItemBuscarConfiguracoesResponse>;

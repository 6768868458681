import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { EnumNatureza, FinalidadesNatureza, TipoNaturezaOperacao } from 'constants/naturezasOperacao';
import { z } from 'zod';

// #################################
// ########### SCHEMAS #############
// #################################

export const schemaItemListarNaturezaDaOperacaoResponse = z.object({
  codigo: z.number(),
  finalidade: z.nativeEnum(FinalidadesNatureza),
  nome: z.string(),
  tipo: z.nativeEnum(TipoNaturezaOperacao),
  operacao: z.nativeEnum(EnumNatureza),
  pertenceParceiro: z.boolean(),
});

export const _schemaListarNaturezasDaOperacaoResponse = z.object({
  data: z.array(schemaItemListarNaturezaDaOperacaoResponse),
});

export const _schemaListarNaturezasDaOperacaoParams = z.object({ search: z.string().optional() }).optional();

// #################################
// ########### PARSERS #############
// #################################

export const parseListarNaturezasDaOperacaoResponse = ZodApiResponseParser.createParser(
  _schemaListarNaturezasDaOperacaoResponse,
);

export const parseListarNaturezasDaOperacaoParams = ZodApiParamsParser.createParser(
  _schemaListarNaturezasDaOperacaoParams,
);

// #################################
// ########### TYPES ###############
// #################################

export type ListarNaturezasDaOperacaoResponse = z.infer<typeof _schemaListarNaturezasDaOperacaoResponse>;
export type ItemListarNaturezasDaOperacaoResponse = z.infer<typeof schemaItemListarNaturezaDaOperacaoResponse>;
export type ListarNaturezasDaOperacaoParams = z.infer<typeof _schemaListarNaturezasDaOperacaoParams>;

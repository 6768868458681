import { axiosInstance } from 'api/axiosConfig';
import {
  type ListarCAProdutosParams,
  parseListarCAProdutosParams,
  parseListarCAProdutosResponse,
} from './schemas/listarCAProdutos';
import { API_URL_AGRONOTA } from 'api/urls';
import { parseBuscarProdutoResponse } from 'api/produtos/schemas/buscarProduto';
import {
  type InserirProdutoBody,
  parseInserirProdutoBody,
  parseInserirProdutoResponse,
} from './schemas/inserirProduto';
import { type EditarProdutoBody, parseEditarProdutoBody } from './schemas/editarProduto';
import {
  parseListarProdutosParams,
  parseListarProdutosResponse,
  type ListarProdutosParams,
} from './schemas/listarProdutos';

export const produtos = {
  listar: (params?: ListarProdutosParams) =>
    axiosInstance
      .get(`${API_URL_AGRONOTA}/parceiro/produtos`, { ...parseListarProdutosParams(params) })
      .then(parseListarProdutosResponse),

  // CA -> Central de Automações
  listarCA: (params?: ListarCAProdutosParams) =>
    axiosInstance
      .get(`${API_URL_AGRONOTA}/parceiro/central-automacao/produto`, { ...parseListarCAProdutosParams(params) })
      .then(parseListarCAProdutosResponse),

  buscar: (id: number) =>
    axiosInstance.get(`${API_URL_AGRONOTA}/parceiro/produtos/${id}`).then(parseBuscarProdutoResponse),

  inserir: (body: InserirProdutoBody) =>
    axiosInstance
      .post(`${API_URL_AGRONOTA}/parceiro/produtos`, parseInserirProdutoBody(body))
      .then(parseInserirProdutoResponse),

  editar: (codigo: number, body: EditarProdutoBody) =>
    axiosInstance.put(`${API_URL_AGRONOTA}/parceiro/produtos/${codigo}`, parseEditarProdutoBody(body)),

  excluir: (id: number) => axiosInstance.delete(`${API_URL_AGRONOTA}/parceiro/produtos/${id}`),
};

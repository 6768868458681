import { theme } from '@l_ine/core';
import type { SmartProviderProps } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

export const primaryColor = colorPalette.blue[300];

export const smartTheme = {
  palette: {
    primary: {
      main: primaryColor,
    },
    success: {
      color: colorPalette.green[300],
      main: colorPalette.green[300],
      contrastText: colorPalette.neutral[0],
    },
    danger: {
      color: colorPalette.red[300],
      main: colorPalette.red[300],
      contrastText: colorPalette.neutral[0],
    },
    error: {
      color: colorPalette.red[300],
      main: colorPalette.red[300],
      contrastText: colorPalette.neutral[0],
    },
    warning: {
      color: colorPalette.yellow[300],
      main: colorPalette.yellow[300],
      contrastText: colorPalette.neutral[0],
    },
    paper: colorPalette.neutral[0],
    border: colorPalette.neutral[50],
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: '16px 16px 0 0',
          '& > .MuiTabs-scroller > .MuiTabs-flexContainer > .MuiButtonBase-root': {
            zIndex: 2,
          },
          '&:not(.MuiTabs-selected)': {
            background: colorPalette.neutral[30],
          },
        },
        indicator: {
          top: 0,
          height: '100%',
          background: `linear-gradient(${primaryColor} 0%, ${primaryColor} 3%, ${colorPalette.neutral[0]} 4%, ${colorPalette.neutral[0]} 100%)`,
          zIndex: 1,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 0.5px 2px 0.6px rgba(0, 0, 0, 0.15)',
        },
        root: {
          boxSizing: 'border-box',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        style: {
          cursor: 'pointer',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Lato",---apple-system,BlinkMacSystemFont, "Open Sans","Helvetica Neue",sans-serif',
  },
} as Required<SmartProviderProps>['theme'];

export const lineTheme = theme.createTheme({
  palette: {
    primary: {
      color: primaryColor,
      main: primaryColor,
      contrast: colorPalette.neutral[0],
    },
    secondary: {
      color: colorPalette.neutral[500],
      contrast: '#000',
    },
    success: {
      color: colorPalette.green[300],
      contrast: colorPalette.neutral[0],
    },
  },
});

import { ChunkNaoDisponivel } from 'errors/chunkNaoDisponivel';
import type { LazyRouteFunction, RouteObject } from 'react-router';

type DynamicImportType = () => Promise<{ default: React.ComponentType }>;

const lazyRoute = (componentImport: DynamicImportType, componentName: string): LazyRouteFunction<RouteObject> => {
  return async () => {
    const storageItemName = `refreshed-lazy-chunks`;

    const refreshedChunks = JSON.parse(window.sessionStorage.getItem(storageItemName) || '{}');

    try {
      const { default: Component } = await componentImport();
      refreshedChunks[componentName] = false;

      return { Component };
    } catch (error: any) {
      if (refreshedChunks[componentName]) {
        throw error;
      }
      refreshedChunks[componentName] = true;

      throw new ChunkNaoDisponivel();
    } finally {
      window.sessionStorage.setItem(storageItemName, JSON.stringify(refreshedChunks));
    }
  };
};
export default lazyRoute;

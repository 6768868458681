import { axiosInstance, type DataResponse } from 'api/axiosConfig';

import type { EditarConfiguracoesVisualizacoes, HistoricoLancamentos, TAGS_APRESENTACOES } from './configuracoes.types';
import { API_URL_AGRONOTA } from 'api/urls';
import { parseBuscarConfiguracoesResponse } from './schemas/buscarConfiguracoes';

export const configuracoes = {
  buscar: () => axiosInstance.get('/parceiro/configuracoes').then(parseBuscarConfiguracoesResponse),

  buscarHistoricos: () =>
    axiosInstance.get<DataResponse<HistoricoLancamentos>>(`/parceiro/configuracoes/lancamentos/historico`),

  editarConfiguracoesSimuladorIR: (body: EditarConfiguracoesVisualizacoes) =>
    axiosInstance.patch(`/parceiro/configuracoes/simulador-ir`, body),

  atualizarIntegracaoDominio: (body: EditarConfiguracoesVisualizacoes) =>
    axiosInstance.patch(`/parceiro/configuracoes/integracao-dominio`, body),

  marcarOnboardingComoConcluido: () => axiosInstance.patch(`/parceiro/configuracoes/onboarding`),

  marcarApresentacaoComoVisualizada: (tag: TAGS_APRESENTACOES) =>
    axiosInstance.patch(`/parceiro/configuracoes/apresentacao/${tag}`),

  editarConfiguracaoFunrural: (values: { codigoCliente: number; tipoFunrural: number }) =>
    axiosInstance.patch(
      `${API_URL_AGRONOTA}/parceiro/configuracoes/${values.codigoCliente}/funrural?tipo=${values.tipoFunrural}`,
    ),

  editarHistoricos: (body: HistoricoLancamentos) =>
    axiosInstance.patch(`/parceiro/configuracoes/lancamentos/historico`, body),
};

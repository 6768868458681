import { useQuery } from '@tanstack/react-query';
import { api } from 'api/api';
import { HttpStatusCode } from 'axios';
import { produce } from 'immer';
import { isEmpty } from 'lodash-es';
import { type PropsWithChildren, Suspense, createContext, useCallback, useContext, useState } from 'react';
import { flushSync } from 'react-dom';
import type {
  EscrituracaoRetroativaMutationStatus,
  EscrituracaoRetroativaV2ContextValues,
  StateResponses,
  StateResponses207,
} from './escrituracaoRetroativaV2.types';
import { ModalResumosSobreAsEscrituracoes } from './modalResumosSobreAsEscrituracoes/modalResumosSobreAsEscrituracoes';
import { PopoverEscrituracaoRetroativa } from './popoverEscrituracaoRetroativa/popoverEscrituracaoRetroativa';
import { useModalsEscrituracaoRetroativaState } from './useModalsEscrituracaoRetroativaState';
import { EtapasModal } from './useModalsEscrituracaoRetroativaState.types';
import { usePathname } from '@s_mart/hooks';

import { Loading } from 'components';
import { ModalExplicacaoDaEscrituracaoRetroativa } from './modalExplicacaoDaEscrituracaoRetroativa/modalExplicacaoDaEscrituracaoRetroativa';
import useClarity from 'hooks/useClarity';
import useAnalytics from 'hooks/useAnalytics';
import { CLARITY_TAGS } from 'constants/clarityTags';
import { ANALYTICS_ACTIONS } from 'constants/analyticsActions';
import useAuthStore from 'store/auth/auth';
import lazyWithRetry from 'utils/lazy/lazyWithRetry';

const ModalNCMsSemRegras = lazyWithRetry(() => import('shared/modalNCMsSemRegras'), 'ModalNCMsSemRegras');

const EscrituracaoRetroativaV2Context = createContext<EscrituracaoRetroativaV2ContextValues | null>(null);

const _initialStateValueResponses207: StateResponses207 = {
  omie: [],
  'mes-fechado': [],
  'nao-mapeado': [],
  'ncm-sem-regra': [],
  'nota-sem-ie': [],
};

export const EscrituracaoRetroativaV2Provider = ({ children }: PropsWithChildren) => {
  const [mutationResponses, _setMutationResponses] = useState<StateResponses>([]);
  const [mutationStatus, _setMutationStatus] = useState<EscrituracaoRetroativaMutationStatus>('idle');

  const [responses207, _setResponses207] = useState<StateResponses207>(_initialStateValueResponses207);
  const [contagemEscrituracoesComSucesso, _setContagemEscrituracoesComSucesso] = useState(0);

  const _pathname = usePathname();

  const recursosDisponiveis = useAuthStore((state) => state.recursosDisponiveis);

  const _notasQuery = useQuery({
    queryKey: ['listaNotasEscrituracaoRetroativa', recursosDisponiveis?.['escrituracao-automatica']],
    async queryFn() {
      const _response = await api.escrituracaoAutomatica.listarNotasEscrituracaoRetroativa();

      const _bodys = _response.data.data.flatMap(({ nomeCliente, codigoCliente, origem, codigosNotas }) => {
        return codigosNotas.map((codigoNota) => ({
          nomeCliente,
          codigoNota,
          codigoCliente,
          origem,
        }));
      });

      const _mapClientes = _bodys.reduce((acc, curr) => {
        acc.set(curr.codigoCliente, curr.nomeCliente);
        return acc;
      }, new Map<number, string>());

      return {
        axiosResponse: _response,
        bodys: _bodys,
        mapClientes: _mapClientes,
      };
    },
    enabled: Boolean(
      _pathname === 'automacoes' && recursosDisponiveis !== null && recursosDisponiveis['escrituracao-automatica'],
    ),
    gcTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
  });

  const totalNotas = _notasQuery.data?.bodys.length ?? 0;

  const escriturarRetroativamenteMutation = useCallback(async () => {
    const _bodys = _notasQuery.data?.bodys;

    // Se não tiver nenhum body, dispara um erro, pois não deveria ser chamada essa função
    if (!_bodys?.length) {
      throw new Error('Não há notas para escriturar', {
        cause: {
          response: _notasQuery.data,
          bodys: _bodys,
        },
      });
    }
    flushSync(() => {
      _setMutationStatus('pending');
    });

    for (const _body of _bodys) {
      try {
        const _responses = await Promise.all([api.escrituracaoAutomatica.escriturarNotaDeFormaRetroativa(_body)]);

        flushSync(async () => {
          for (const _response of _responses) {
            _setMutationResponses(
              produce((responses) => {
                responses.push(_response);
              }),
            );

            const _omie = Boolean(
              _response.status === HttpStatusCode.MultiStatus && _response.data?.data.tipoErro === 'omie',
            );

            // Primeiro verifica se o Status é 204 (HttpStatusCode.NoContent) e se for, incrementa o notas escrituradas
            if (_response.status === HttpStatusCode.NoContent || _omie) {
              _setContagemEscrituracoesComSucesso((v) => {
                return v + 1;
              });
              continue;
            }

            // Se o status não for 204, verifica se o body é nulo, se for, continua o loop, pois não é algo esperado
            if (!_response.data) {
              continue;
            }

            // Se o status for 207 (HttpStatusCode.MultiStatus), verifica o tipo de erro e adiciona no array de erros correspondente
            if (_response.status === HttpStatusCode.MultiStatus && !_omie) {
              const _data = _response.data.data;
              _setResponses207(
                produce((responses207) => {
                  if (!responses207[_data.tipoErro]) {
                    responses207[_data.tipoErro] = [];
                  }

                  //@ts-expect-error - Avisa que não é possível fazer um push em um never, pois há um union de chaves tipoErro, onde seus valores são diferentes
                  responses207[_data.tipoErro].push(_data);
                }),
              );
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    }

    _setMutationStatus('fulfilled');
  }, [_notasQuery.data?.bodys]);

  const resetarEscrituracaoRetroativa = useCallback(async () => {
    await _notasQuery.refetch();
    _setMutationResponses([]);
    _setMutationStatus('idle');
    _setResponses207(_initialStateValueResponses207);
    _setContagemEscrituracoesComSucesso(0);
  }, [_initialStateValueResponses207, mutationResponses, _notasQuery]);

  const modalsResultados = useModalsEscrituracaoRetroativaState({
    mutationResponses,
    mutationStatus,
    mapClientes: _notasQuery.data?.mapClientes ?? new Map<number, string>(),
    responses207,
    contagemEscrituracoesComSucesso,
    resetarEscrituracaoRetroativa,
  });

  const handleEscriturarNovamente = useCallback(async () => {
    modalsResultados.handleFecharModal();
    await flushSync(async () => {
      await resetarEscrituracaoRetroativa();
    });

    await escriturarRetroativamenteMutation();
  }, [_notasQuery, escriturarRetroativamenteMutation, modalsResultados]);

  const [_modalExplicacaoSobreEscrituracaoRetroativa, _setModalExplicacaoSobreEscrituracaoRetroativa] = useState(false);

  const { setTag } = useClarity();
  const { analyticsEventTracker } = useAnalytics();

  const handleAbrirExplicacaoSobreEscriturarRetroativamente = () => {
    setTag(CLARITY_TAGS.ESCRITURACAO_AUTOMATICA.ESCRITURACAO_RETROATIVA);
    analyticsEventTracker({ action: ANALYTICS_ACTIONS.ESCRITURACAO_AUTOMATICA.ESCRITURACAO_RETROATIVA });

    _setModalExplicacaoSobreEscrituracaoRetroativa(true);
  };
  const handleFecharExplicacaoSobreEscriturarRetroativamente = () =>
    _setModalExplicacaoSobreEscrituracaoRetroativa(false);

  return (
    <EscrituracaoRetroativaV2Context.Provider
      value={{
        totalNotas,
        responses207,
        mutationStatus,
        modalsResultados,
        mutationResponses,
        contagemEscrituracoesComSucesso,
        handleEscriturarNovamente,
        escriturarRetroativamenteMutation,
        handleAbrirExplicacaoSobreEscriturarRetroativamente,
      }}
    >
      {mutationStatus !== 'idle' ? (
        <PopoverEscrituracaoRetroativa
          onClose={async () => {
            modalsResultados.handleFecharModal();
            await resetarEscrituracaoRetroativa();
          }}
        />
      ) : null}

      {_modalExplicacaoSobreEscrituracaoRetroativa ? (
        <ModalExplicacaoDaEscrituracaoRetroativa onClose={handleFecharExplicacaoSobreEscriturarRetroativamente} />
      ) : null}
      {modalsResultados.etapa === EtapasModal.ResumoDocumentos ? <ModalResumosSobreAsEscrituracoes /> : null}

      {modalsResultados.etapa === EtapasModal.ModalNCMsPendentes ? (
        <Suspense fallback={<Loading loading />}>
          <ModalNCMsSemRegras
            onClose={async () => {
              modalsResultados.handleFecharModal();
              await resetarEscrituracaoRetroativa();
            }}
          />
        </Suspense>
      ) : null}
      {children}
    </EscrituracaoRetroativaV2Context.Provider>
  );
};

export const useEscrituracaoRetroativaV2Context = () => {
  const _context = useContext(EscrituracaoRetroativaV2Context);

  if (_context === null) {
    throw new Error('useEscrituracaoAutomaticaV2Context deve ser usado dentro de um EscrituracaoAutomaticaV2Provider');
  }

  return _context;
};

import { Dialog } from '@s_mart/core';
import { useMemo } from 'react';
import { useEscrituracaoRetroativaV2Context } from '../escrituracaoRetroativaV2';
import { mapEtapasModal } from './etapas/etapasModalResumosSobreAsEscrituracoes.static';

export function ModalResumosSobreAsEscrituracoes() {
  const { modalsResultados } = useEscrituracaoRetroativaV2Context();

  const Etapa = useMemo(() => mapEtapasModal[modalsResultados.etapa] ?? null, [modalsResultados.etapa]);

  if (!Etapa) return null;

  return (
    <Dialog open disableBackdropClick maxWidth='lg' fullWidth>
      <Dialog.Header>
        <Etapa.Header />
      </Dialog.Header>
      <Dialog.Content>
        <Etapa.Content />
      </Dialog.Content>
      <Dialog.Footer>
        <Etapa.Footer />
      </Dialog.Footer>
    </Dialog>
  );
}

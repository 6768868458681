import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type PathSourceProps, LIcon, Typography, AppBar as AppBarSmart, Divider } from '@s_mart/core';
import { lineSignOut, lineUserTie, lineQuestionCircle } from '@s_mart/light-icons';
import { lineChevronDown } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';
import HelpMenu from './helpMenu/helpMenu';

import {
  appBarProfileActions,
  appBarProfileActionsAgronota,
  opcoesAjudaAgro,
  opcoesAjudaSimplesCte,
} from './appBar.static';
import { AppBarButton, HelpButton } from './appBar.styles';
import useAuthStore from 'store/auth/auth';
import useSistema from 'hooks/useSistema';
import { Novidades } from 'shared/novidades/novidades';
import { ModalMultiEmpresa } from 'shared/modalMultiEmpresa/modalMultiEmpresa';

export type HelpMenuState = (EventTarget & HTMLDivElement) | null;

const AppBar = () => {
  const [perfil, logout] = useAuthStore((state) => [state.perfil, state.logout]);
  const [modalMultiempresa, setModalMultiempresa] = useState(false);

  const navigate = useNavigate();
  const { code } = useSistema();

  const [menuOpen, setMenuOpen] = useState<HelpMenuState>(null);

  return (
    <>
      {modalMultiempresa && <ModalMultiEmpresa onClose={() => setModalMultiempresa(false)} />}
      <AppBarSmart>
        <AppBarButton
          menuOpen={menuOpen}
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(e?.currentTarget);
          }}
        >
          <LIcon icon={lineQuestionCircle} size='28px' removeMargin />
          <Typography variant='bodySM'>Ajuda</Typography>
          <LIcon icon={lineChevronDown} size='18px' removeMargin />
        </AppBarButton>
        {code === 2 && <Novidades />}
        <AppBarSmart.Profile title={perfil.razao_social} user={perfil.nome}>
          {code === 1 &&
            appBarProfileActions(navigate).map((action) => {
              if (!action.hide) {
                return (
                  <AppBarSmart.Action
                    key={action.label}
                    icon={action.icon as PathSourceProps}
                    onClick={action?.onClick as React.MouseEventHandler}
                  >
                    {action.label}
                  </AppBarSmart.Action>
                );
              }
            })}
          {code === 2 &&
            appBarProfileActionsAgronota(navigate).map((action) => {
              if (!action.hide) {
                return (
                  <AppBarSmart.Action
                    key={action.label}
                    icon={action.icon as PathSourceProps}
                    onClick={action?.onClick as React.MouseEventHandler}
                  >
                    {action.label}
                  </AppBarSmart.Action>
                );
              }
            })}
          {perfil.multi && (
            <AppBarSmart.Action icon={lineUserTie} onClick={() => setModalMultiempresa(true)}>
              Trocar empresa
            </AppBarSmart.Action>
          )}
          <Divider orientation='horizontal' color={colorPalette.neutral[30]} />
          <AppBarSmart.Action icon={lineSignOut} onClick={() => logout()}>
            Sair
          </AppBarSmart.Action>
        </AppBarSmart.Profile>
      </AppBarSmart>
      <HelpMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
        {code === 1 &&
          opcoesAjudaSimplesCte(perfil).map((item) => (
            <HelpButton onClick={item.onClick as React.MouseEventHandler} key={item.label}>
              <LIcon icon={item.icon as PathSourceProps} size='18px' removeMargin />
              <Typography variant='bodySM'>{item.label}</Typography>
            </HelpButton>
          ))}
        {code === 2 &&
          opcoesAjudaAgro.map((item) => (
            <HelpButton onClick={item.onClick as React.MouseEventHandler} key={item.label}>
              {item.icon ? (
                <LIcon icon={item.icon as PathSourceProps} size='18px' removeMargin color={item.color} />
              ) : (
                <img src={item.imgPath} width='18px' />
              )}
              <Typography variant='bodySM' color={item.color}>
                {item.label}
              </Typography>
            </HelpButton>
          ))}
      </HelpMenu>
    </>
  );
};

export { AppBar };

import { URL_AGRONOTA, URL_SIMPLESCTE } from 'api/urls';
import { EnumSistemas, type Sistema, sistemas } from 'constants/sistemas';

export const siteConfig = {
  siteName: 'Parceiro',
  siteIcon: '',
  logo: '/static/svgs/logo.svg',
  logoWhite: '',
  clientes: '/clientes',
  footerText: `© Copyright ${new Date().getFullYear()} Parceiro. Todos os direitos reservados.`,
};

const systemData = sistemas[window?.location?.hostname?.split('.')[1]];
const suportURL = `https://api.whatsapp.com/send?phone=${systemData.whatsApp}`;
const POSTHOG_HOST = 'https://us.i.posthog.com';

// Cada sistema deve ter seu próprio POSTHOG_API
const POSTHOG_API_KEY_AGRONOTA = 'phc_L3G5M53394uhjxRv6qCq2UPrFwCqepLapm9wLVUFxBb';

// AGRONOTA
let Config: SystemConfig = {
  POSTHOG_API_KEY: POSTHOG_API_KEY_AGRONOTA,
  POSTHOG_HOST,
  suportURL,
  systemURL: URL_AGRONOTA,
  systemData,
};

// SIMPLESCTE
if (systemData.code === EnumSistemas.SIMPLESCTE) {
  Config = {
    POSTHOG_API_KEY: null,
    POSTHOG_HOST,

    suportURL,
    systemURL: URL_SIMPLESCTE,
    systemData: systemData,
  };
}

export { Config, type SystemConfig };

// ------------- Tipagens ----------------

type SystemConfig = {
  POSTHOG_API_KEY: string | null;
  POSTHOG_HOST: string;

  suportURL: string;
  systemURL: string;
  systemData: Sistema;
};

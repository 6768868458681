import { Button, Grid, Typography } from '@s_mart/core';
import { DataTable } from '@s_mart/data';
import { useEscrituracaoRetroativaV2Context } from 'contexts/escrituracaoRetroativaV2/escrituracaoRetroativaV2';
import {
  EtapasModal,
  type StateEtapaModalResumoDocumentosValue,
} from 'contexts/escrituracaoRetroativaV2/useModalsEscrituracaoRetroativaState.types';
import { useEtapaResumoDocumentosNaoEscrituradosPorProdutor } from './useEtapaResumoDocumentosNaoEscrituradosPorProdutor';
import useAuthStore from 'store/auth/auth';
import { EnumAutomacaoAgronota } from 'constants/configuracoes';

const _Header = () => <>Resumo das notas revisadas</>;

const _Footer = () => {
  const { modalsResultados, responses207 } = useEscrituracaoRetroativaV2Context();
  const possuiAutomacaoSAAS = useAuthStore(
    (s) => s.configuracoes?.automacaoAgronota === EnumAutomacaoAgronota.CentralFinanceira,
  );
  const _quantidadeDeNCMs = new Set(responses207['ncm-sem-regra'].map(({ ncm }) => ncm));
  const _ncmsEmPluralOuSingular = _quantidadeDeNCMs.size > 1 ? `NCMs pendentes` : 'NCM pendente';

  if (possuiAutomacaoSAAS) {
    return <Button onClick={modalsResultados.handleFecharModal}>Fechar</Button>;
  }

  return (
    <>
      <Button variant='neutral' onClick={modalsResultados.handleFecharModal}>
        {_quantidadeDeNCMs.size > 0 ? `Não quero resolver agora` : 'Fechar'}
      </Button>

      {_quantidadeDeNCMs.size > 0 ? (
        <Button onClick={modalsResultados.handleProximaEtapa}>Ver {_ncmsEmPluralOuSingular}</Button>
      ) : null}
    </>
  );
};

const _Content = () => {
  const { contagemEscrituracoesComSucesso, modalsResultados, responses207 } = useEscrituracaoRetroativaV2Context();
  const { colunas } = useEtapaResumoDocumentosNaoEscrituradosPorProdutor();
  const _documentosEmPluralOuSingular = contagemEscrituracoesComSucesso > 1 ? 'documentos' : 'documento';

  const _quantidadeDeNCMs = new Set(responses207['ncm-sem-regra'].map(({ ncm }) => ncm));
  const _ncmsEmPluralOuSingular = _quantidadeDeNCMs.size > 1 ? `NCMs sem regras` : 'NCM sem regra';

  return (
    <Grid container spacing={2}>
      {contagemEscrituracoesComSucesso > 0 && (
        <Grid item xs={12}>
          <Typography variant='titleXS'>
            Lancei para você de forma automática {contagemEscrituracoesComSucesso} {_documentosEmPluralOuSingular}.
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant='bodySM'>
          {_quantidadeDeNCMs.size > 0
            ? `Enquanto revisava as notas pendentes contei ${_quantidadeDeNCMs.size} ${_ncmsEmPluralOuSingular}.`
            : null}{' '}
          Veja abaixo um resumo por produtores:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          getRowId={(row: StateEtapaModalResumoDocumentosValue) => row.produtor}
          columns={colunas}
          rows={Object.values(modalsResultados.dadosEtapas[EtapasModal.ResumoDocumentos])}
        />
      </Grid>
    </Grid>
  );
};

export const EtapaResumoDocumentosNaoEscrituradosPorProdutor = {
  Header: _Header,
  Footer: _Footer,
  Content: _Content,
};

import { useEffect } from 'react';

const useHead = () => {
  useEffect(() => {
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = '/static/icons/favicon.ico';
    document.head.appendChild(favicon);
  }, []);
};

export { useHead };

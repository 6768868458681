import { listQueryParamsSchema, metaSchema } from 'api/axiosConfig';
import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

const schemaItemListarNCMsParams = listQueryParamsSchema.optional();

const schemaItemListarNCMsResponse = z.object({
  funruralPf: z.number(),
  funruralPj: z.number(),
  ncm_codigo: z.string(),
  nome: z.string(),
});

const schemaListarNCMsResponse = z.object({
  data: z.array(schemaItemListarNCMsResponse),
  meta: metaSchema,
});

export const parseListarNCMsParams = ZodApiParamsParser.createParser(schemaItemListarNCMsParams);
export const parseListarNCMsResponse = ZodApiResponseParser.createParser(schemaListarNCMsResponse);

export type ListarNCMsParams = z.infer<typeof schemaItemListarNCMsParams>;
export type ListarNCMsResponse = z.infer<typeof schemaListarNCMsResponse>;
export type ItemListarNCMsResponse = z.infer<typeof schemaItemListarNCMsResponse>;

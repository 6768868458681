import { axiosInstance } from 'api/axiosConfig';
import { API_URL_AGRONOTA } from 'api/urls';
import { parseBuscarNaturezaOperacaoResponse } from './schemas/naturezaOperacaoResponseSchemas';
import {
  type ListarNaturezasDisponiveisParams,
  parseListarNaturezasDisponiveisResponse,
} from './schemas/listarNaturezasDisponiveis';
import {
  type EditarNaturezaOperacaoBody,
  type InserirNaturezaOperacaoBody,
  parseEditarNaturezaOperacaoBody,
  parseInserirNaturezaOperacaoBody,
} from './schemas/inserirNaturezaDaOperacao';
import { parseListarNaturezasDaOperacaoParams } from './schemas/listarNaturezasDaOperacao';

export const naturezasOperacao = {
  listarDisponiveis: (params?: ListarNaturezasDisponiveisParams) =>
    axiosInstance
      .get(`${API_URL_AGRONOTA}/parceiro/naturezas-operacao/disponiveis`, {
        ...parseListarNaturezasDaOperacaoParams(params),
      })
      .then(parseListarNaturezasDisponiveisResponse),

  buscar: (codigo: number) =>
    axiosInstance
      .get(`${API_URL_AGRONOTA}/parceiro/naturezas-operacao/${codigo}`)
      .then(parseBuscarNaturezaOperacaoResponse),

  inserir: (body: InserirNaturezaOperacaoBody) =>
    axiosInstance.post(
      `${API_URL_AGRONOTA}/parceiro/naturezas-operacao/inserir`,
      parseInserirNaturezaOperacaoBody(body),
    ),

  alterarSituacao: (body: { codigo: number; ativa: boolean }) =>
    axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/naturezas-operacao`, body),

  editar: (codigo: number, body: EditarNaturezaOperacaoBody) =>
    axiosInstance.put(
      `${API_URL_AGRONOTA}/parceiro/naturezas-operacao/${codigo}`,
      parseEditarNaturezaOperacaoBody(body),
    ),
};

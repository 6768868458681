import { type DataResponse, axiosInstance } from 'api/axiosConfig';
import type { ConfiguracaoUsuario, ConfiguracaoUsuarioBody } from './usuarios.types';

export const usuarios = {
  listar: () => axiosInstance.get('/parceiro/users'),

  buscarConfiguracoes: () => axiosInstance.get<DataResponse<ConfiguracaoUsuario>>(`/parceiro/usuarios/configuracoes`),

  excluir: (userId: string) => axiosInstance.delete(`/parceiro/users/${userId}`),

  editar: (body: any) => axiosInstance.patch(`/parceiro/user/${body.codigo}`, body),

  atualizarConfiguracoes: (config: ConfiguracaoUsuarioBody) =>
    axiosInstance.patch(`/parceiro/usuarios/configuracoes`, config),
};

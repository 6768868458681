import { TipoNota } from 'api/escrituracaoAutomatica/escrituracaoAutomatica.types';
import {
  EscrituracaoRetroativaMutationStatus,
  StateResponses,
  StateResponses207,
} from './escrituracaoRetroativaV2.types';

export enum EtapasModal {
  ModalFechada,
  ResumoDocumentos,
  ModalNCMsPendentes,
  // Adicionar mais steps conforme a necessidade
}

export type StateEtapaModalResumoDocumentos = {
  [codigoCliente: _StateEtapaModalResumoDocumentosKey]: StateEtapaModalResumoDocumentosValue;
};
type _StateEtapaModalResumoDocumentosKey = number;
export type StateEtapaModalResumoDocumentosValue = {
  produtor: string;
  quantidadeNotasEscrituradasOuIgnoradas: number;
  quantidadeNotasNaoEscrituradas: number;
};

export type StateEtapaModalNCMsSemRegras = {
  [ncm: _StateEtapaModalNCMsSemRegrasKey]: StateEtapaModalNCMsSemRegrasValue;
};
type _StateEtapaModalNCMsSemRegrasKey = string;
export type StateEtapaModalNCMsSemRegrasValue = {
  ncm: string;
  tipoNota: TipoNota;
  tipoNotaLabel: string;
  quantidadeNotasNaoEscrituradas: number;
};

export type DadosEtapasModal = {
  [EtapasModal.ResumoDocumentos]: StateEtapaModalResumoDocumentos;
  [EtapasModal.ModalNCMsPendentes]: StateEtapaModalNCMsSemRegras | null;
};

export type UseModalsEscrituracaoRetroativaState = {
  mutationResponses: StateResponses;
  responses207: StateResponses207;
  mutationStatus: EscrituracaoRetroativaMutationStatus;
  mapClientes: Map<number, string>;
  contagemEscrituracoesComSucesso: number;
  resetarEscrituracaoRetroativa(): void;
};

import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { FinalidadesNatureza, TipoNaturezaOperacao } from 'constants/naturezasOperacao';
import { z } from 'zod';

const schemaItemlistarNaturezasDisponiveisResponse = z.object({
  codigo: z.number(),
  nome: z.string(),
  ativa: z.boolean(),
  cfop: z.string(),
  cfopNome: z.string(),
  pertenceParceiro: z.boolean(),
  tipo: z.nativeEnum(TipoNaturezaOperacao),
  finalidade: z.nativeEnum(FinalidadesNatureza),
});
const schemaListarNaturezasDisponiveisResponse = z.object({
  data: z.array(schemaItemlistarNaturezasDisponiveisResponse),
});

const _listarNaturezasDisponiveisParams = z
  .object({
    search: z.string().optional(),
  })
  .optional();

export const parseListarNaturezasDisponiveisResponse = ZodApiResponseParser.createParser(
  schemaListarNaturezasDisponiveisResponse,
);
export const parseListarNaturezasDisponiveisParams = ZodApiParamsParser.createParser(_listarNaturezasDisponiveisParams);

export type ListarNaturezasDisponiveisResponse = z.output<typeof schemaListarNaturezasDisponiveisResponse>;
export type ListarNaturezasDisponiveisParams = z.infer<typeof _listarNaturezasDisponiveisParams>;
export type ItemListarNaturezasDisponiveisResponse = ListarNaturezasDisponiveisResponse['data'][number];

import { QueryCache, QueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';

const defaultQueryConfig = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchInterval: 0,
  retry: false,
  gcTime: 0,
  staleTime: 0,
};

export const useQueryClient = () => {
  const _token = useAuthStore(({ token }) => token);
  const _handleErrors = useAppStore(state => state.handleErrors);
  const _queryCliente = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: _handleErrors,
        }),
        defaultOptions: {
          queries: {
            ...defaultQueryConfig,
            enabled: !!_token,
          },
        },
      }),
    [_token]
  );

  return _queryCliente;
};
2;

import { Stack } from '@mui/material';
import { Button, Dialog, Typography } from '@s_mart/core';
import { useModalBoleto } from 'shared/modalBoleto/context/modalBoletoProvider';
import { Recibo } from 'shared/modalBoleto/modalBoleto.static';

export const SituacaoVariosBoletosVencidos = () => {
  const { handleRedirecionarMeuPlano, handleFalarComSuporte } = useModalBoleto();

  return (
    <>
      <Dialog.Header>Ops, nosso robô ainda não identificou alguns pagamentos</Dialog.Header>
      <Dialog.Content>
        <Stack gap={2} alignItems='center'>
          <img src={Recibo} width={150} />
          <Typography variant='bodyLG'>Acesse abaixo o meu plano para verificar as faturas em aberto</Typography>
          <Button fullWidth onClick={handleRedirecionarMeuPlano}>
            Ir para meu plano
          </Button>
        </Stack>
      </Dialog.Content>
      <Dialog.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='bodyXS'>Foi um engano? Não se preocupe, Nesse caso,</Typography>
        <Button variant='outlined' onClick={handleFalarComSuporte}>
          Clique aqui para falar com o suporte
        </Button>
      </Dialog.Footer>
    </>
  );
};

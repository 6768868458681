import { IconButton, LIcon } from '@s_mart/core';
import { lineTimes } from '@s_mart/light-icons';
import { useEscrituracaoRetroativaV2Context } from '../escrituracaoRetroativaV2';
import { PopoverEscrituracaoRetroativaCloseButtonProps } from './popoverEscrituracaoRetroativa.types';

export const PopoverEscrituracaoRetroativaCloseButton = ({
  onClick,
}: PopoverEscrituracaoRetroativaCloseButtonProps) => {
  const { mutationStatus } = useEscrituracaoRetroativaV2Context();

  if (mutationStatus !== 'fulfilled') {
    return null;
  }

  return (
    <IconButton color='neutral' variant='text' size='small' sx={{ h: '24px', w: '24px' }} onClick={onClick}>
      <LIcon removeMargin icon={lineTimes} size={'24px'} />
    </IconButton>
  );
};

import type { SetNotificationProps } from '@s_mart/typed';

export enum ModalBoletoSituacao {
  /**
   * Conta bloqueada com 1 boleto vencido
   */
  UNICO_BOLETO_VENCIDO,
  /**
   * Conta bloqueada com mais de 1 boleto vencido
   */
  VARIOS_BOLETOS_VENCIDOS,
  /**
   * Conta bloqueada com 1 boleto vencido que foi marcado como pago há mais de 1 dia
   */
  PAGAMENTO_NAO_IDENTIFICADO,
  /**
   * Conta não bloqueada com 1 boleto novo em aberto (não vencido)
   */
  BOLETO_DISPONIVEL,
  /**
   * modal de confirmação depois de desbloqueio manual por 1 dia
   */
  CONFIRMACAO_DESBLOQUEIO,
}

type ModalClienteOnboarding = {
  primeiroCliente: boolean;
} | null;

export interface AppStates {
  notification: SetNotificationProps | null;
  loading: boolean;
  mostrarOnboarding: boolean;
  modalClienteOnboarding: ModalClienteOnboarding;
  mostrarAvisoPrimeiroCliente: boolean;
  modalBoleto: ModalBoletoSituacao | null;
}

export interface AppStore extends AppStates {
  setNotification: (notification: SetNotificationProps | null) => void;
  setLoading: (loading: boolean) => void;
  handleErrors: (error: any) => void;
  setMostrarOnboarding: (mostrar: boolean) => void;
  setModalClienteOnboarding: (cliente: ModalClienteOnboarding) => void;
  setModalBoleto: (situacao: ModalBoletoSituacao | null) => void;
  setMostrarAvisoPrimeiroCliente(mostrar: boolean): void;
  limparStore(): void;
}

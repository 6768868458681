import { axiosInstance, type DataResponse } from 'api/axiosConfig';
import type { ListarProdutoresResponse } from './produtores.types';

export const produtores = {
  listar: (search?: string) =>
    axiosInstance.get<DataResponse<ListarProdutoresResponse[] | null>>('/parceiro/produtores', { params: search }),

  inserir: (body: { cpfCnpj: number; nome: string; tipoPessoa: number }) =>
    axiosInstance.post('/parceiro/produtores', body),

  editar: (body: { codigo: number; cpfCnpj: number; nome: string; tipoPessoa: number }) =>
    axiosInstance.put('/parceiro/produtores', body),

  excluir: (codigo: number) => axiosInstance.delete(`/parceiro/produtores/${codigo}`),
};
